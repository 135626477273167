import React, { useState } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useNavigation } from 'react-router-dom'
import ulkeler from '../../metinler/ulkeler.json'

import Yetkili from '../../Components/Yetkili'

import { api,server,prefix,endpoints } from '../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Olustur() {

  const { user,token } = useContext(MainContext)

  const navigate                            = useNavigate()
  const { t }                               = useTranslation();

  const [ad,setAd]                          = useState();
  const [soyad,setSoyad]                    = useState();
  const [rol,setRol]                        = useState();
  const [kk,setKK]                          = useState();
  const [ozetBilgiler,setOzetBilgiler]      = useState();
  const [telefon,setTelefon]                = useState();
  const [email,setEmail]                    = useState();
  const [ulke,setUlke]                      = useState();
  const [sehir,setSehir]                    = useState();
  const [cinsiyet,setCinsiyet]              = useState();
  const [yetkili,setYetkili]                = useState(user);

  const [unvan,setUnvan]                    = useState();
  const [hastane,setHastane]                = useState();

  const [telefonKodu,setTelefonKodu]        = useState("+")
  const [ulkeKodu,setUlkeKodu]              = useState("")
  const [dilKodu,setDilKodu]                = useState("")
  const [saatDilimi,setSaatDilimi]          = useState()
  const [sehirler,setSehirler]              = useState([])
  const [loading,setLoading]                = useState(false)

  const ulkeSec = (ulke) => {

    if(ulke == ""){
      setSehirler([])
      return;
    }

    setUlke(ulke);
    setSehir("");

    const secilenUlke = ulkeler.find(x => x.adi == ulke);

    setTelefonKodu(secilenUlke.telefon_kodu)
    setSaatDilimi(secilenUlke.saat_dilimi)
    setUlkeKodu(secilenUlke.kod)
    setDilKodu(secilenUlke.dil_kodu)

    setSehirler(secilenUlke.sehirler);
  }

  const olustur = () => {
    setLoading(true);

    const formData = {
      kullanici_kodu : kk,
      ad,
      soyad,
      rol,
      cinsiyet,
      email,
      ulke_kodu : ulkeKodu,
      dil_kodu : dilKodu,
      saat_dilimi : saatDilimi,
      telefon_kodu : telefonKodu,
      telefon,
      ozet_bilgiler : ozetBilgiler,
      ulke,
      sehir,
      yetkili_id : yetkili.id,
      unvan : rol == "SP" ? unvan : null,
      hastane : rol == "SP" ? hastane : null,
    }

    const url = server + prefix + endpoints.kullanici_olustur;
    api.request(url,"POST",formData,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      setLoading(false);
      const { data : responseData } = response;
      
      const { hata,mesaj } = responseData;

      if(!hata){
        setAd("");
        setSoyad("");
        setCinsiyet("");
        setRol("");
        setKK("");
        setSaatDilimi("");
        setTelefonKodu("+");
        setUlkeKodu("");
        setDilKodu("");
        setOzetBilgiler("");
        setTelefon("");
        setEmail("");
        setUlke("");
        setSehir("");
        setUnvan("");
        setHastane("");
      }
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam')
      }).then((x) => {
      if(x.isConfirmed){
        if(!hata){
          navigate("/kullanici/listele")
        }
      }
    })

    }).catch((err) => {
      setLoading(false);
    })
  }

  return (
    <Layout baslik={ t('kullanici_olustur') }>
        <div className="row">
          <div className="col-md-8">

          <div className="card shadow">
            <div className="card-body">

              <div className="row">
                <div className="col-md-6">
                  
                  <Yetkili yetkili={ yetkili } setYetkili={ setYetkili } rol={ rol }/>
                  
                  <div className="row">
                    <div className="col-md-6">

                      <div className="form-group">
                        <label htmlFor="ad"> <small className="text-danger">*</small> { t('ad') }</label>
                        <input id="ad" type="text" className="form-control" value={ ad } onChange={(e) => setAd(e.target.value)} />
                      </div>

                    </div>
                    <div className="col-md-6">

                      <div className="form-group">
                        <label htmlFor="soyad"><small className="text-danger">*</small> { t('soyad') }</label>
                        <input id="soyad" type="text" className="form-control" value={ soyad } onChange={(e) => setSoyad(e.target.value)} />
                      </div>

                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="cinsiyet"><small className="text-danger">*</small> { t('cinsiyet') }</label>
                    <select defaultValue="" name="cinsiyet" id="cinsiyet" className="form-control" value={ cinsiyet } onChange={ (e) => setCinsiyet(e.target.value) }>
                      <option value="" disabled>{ t("secim_yapilmadi") }</option>
                      <option value="KADIN">{ t('KADIN') }</option>
                      <option value="ERKEK">{ t('ERKEK') }</option>
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="rol"><small className="text-danger">*</small> { t('rol') }</label>
                    <select defaultValue="" name="rol" id="rol" className="form-control" value={ rol } onChange={ (e) => setRol(e.target.value) }>
                      <option value="" disabled>{ t("secim_yapilmadi") }</option>
                      {
                        user.rol == "ADMIN" &&
                        <>
                        <option value="ADMIN">{ t('ADMIN') }</option>
                        <option value="SUBADMIN">{ t('SUBADMIN') }</option>
                        </>
                      }
                      <option value="SP">{ t('SP') }</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="kk"><small className="text-danger">*</small> { t('kullanici_kodu') }</label>
                    <input id="kk" type="text" className="form-control" value={ kk } onChange={(e) => setKK(e.target.value)} autoComplete="new-password"/>
                  </div>

                </div>

                <div className="col-md-6">

                  <div className="form-group">
                    <label htmlFor="ulke"><small className="text-danger">*</small> { t('ulke') }</label>
                    <select defaultValue="" name="ulke" id="ulke" className="form-control" value={ ulke } onChange={ (e) => ulkeSec(e.target.value) }>
                      <option value="" disabled>{ t("secim_yapilmadi") }</option>
                      {
                        ulkeler.map(ulke => (
                          <option key={ ulke.adi } value={ ulke.adi }>{ ulke.adi }</option>
                        ))
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="sehir"><small className="text-danger">*</small> { t('sehir') }</label>
                    <select defaultValue="" name="sehir" id="sehir" className="form-control" value={ sehir } onChange={(e) => setSehir(e.target.value)}>
                      <option value="" disabled>{ t("secim_yapilmadi") }</option>
                      {
                        sehirler.map(sehir => (
                          <option key={ sehir } value={ sehir }>{ sehir }</option>
                        ))
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="telefon"><small className="text-danger">*</small> { t("telefon_numarasi") }</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="telefon">{ telefonKodu }</span>
                      </div>
                      <input id="telefon" type="text" className="form-control" value={ telefon } onChange={(e) => setTelefon(e.target.value)} />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">{ t('email') }</label>
                    <input id="email" type="text" className="form-control" value={ email } onChange={(e) => setEmail(e.target.value)} />
                  </div>

                  {
                    (rol == "SP") &&
                    <>
                    <div className="form-group">
                      <label htmlFor="unvan">{ t('unvan') }</label>
                      <input id="unvan" type="text" className="form-control" value={ unvan } onChange={(e) => setUnvan(e.target.value)} autoComplete="new-password"/>
                    </div>
                    </>
                  }

                </div>

                <div className="col-md-12">

                  {
                    (rol == "SP") &&
                    <>
                    <div className="form-group">
                      <label htmlFor="hastane">{ t('hastane') }</label>
                      <textarea id="hastane" rows={ 3 } type="text" className="form-control" value={ hastane } onChange={(e) => setHastane(e.target.value)}></textarea>
                    </div>
                    </>
                  }

                  <div className="form-group">
                    <label htmlFor="ozetBilgiler">{ t('ozet_bilgiler') }</label>
                    <textarea id="ozetBilgiler" rows={ 3 } type="text" className="form-control" value={ ozetBilgiler } onChange={(e) => setOzetBilgiler(e.target.value)}></textarea>
                  </div>

                  <div className="alert alert-secondary">
                    <p className="mb-0">{ t("parola_sms_olacak_gonderilecek") }</p>
                  </div>

                  <button className="btn btn-dark btn-block btn-lg" onClick={() => olustur()}>
                    {
                      loading ? 
                      <>
                        <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                      </>
                      :
                      <>
                        <i className="fas fa-check"></i> { t("kullanici_olustur") }
                      </>
                    }
                  </button>
                </div>
              </div>

            </div>
          </div>

          </div>
        </div>
    </Layout>
  )
}
