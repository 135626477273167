import axios from 'axios'

const server    = "https://demoapi.enyazilim.com";
const prefix    = "/api";
const endpoints = {
    access_token                    : "/access-token",
    kullanici_bilgileri             : "/kullanici/bilgileri",
    kullanici_arsivden_cikar        : "/kullanici/arsivden-cikar/:id",
    kullanici_sil                   : "/kullanici/sil/:id",

    kullanici_olustur               : "/kullanici/olustur",
    kullanici_listele               : "/kullanici/listele",
    kullanici_bilgi                 : "/kullanici/bilgi/:id",
    kullanici_guncelle              : "/kullanici/guncelle/:id",

    parola_gonder                   : "/kullanici/parola-gonder/:id",
    parola_sifirla                  : "/kullanici/parola-sifirla",

    hasta_olustur                   : "/hasta/olustur",
    hasta_listele                   : "/hasta/listele",
    hasta_bilgi                     : "/hasta/bilgi/:id",
    hasta_guncelle                  : "/hasta/guncelle/:id",

    recete_ayarlari                 : "/recete/ayarlari",
    recete_ayarlarini_guncelle      : "/recete/ayarlarini-guncelle",
    recete_olustur                  : "/recete/olustur",
    recete_guncelle                 : "/recete/guncelle/:id",
    recete_listele                  : "/recete/listele",
    recete_bilgi                    : "/recete/bilgi/:id",
    recete_sil                      : "/recete/sil/:id",
    recete_onizleme                 : "/recete/onizleme/:id",

    tedavi_ata                      : "/tedavi/ata",
    tedavi_iptal                    : "/tedavi/iptal/:id",
    tedavi_listele                  : "/tedavi/listele",
    tedavi_bilgileri                : "/tedavi/bilgileri/:id",
    aksiyon_ekle                    : "/tedavi/aksiyon-ekle",
    aksiyon_sil                     : "/tedavi/aksiyon-sil/:id",

    uriner_loglari                  : "/uriner/loglari/:id",
    anket_sonuclari                 : "/anket/sonuclari/:id"
}

class Api {
    request(url,method,data,headers = null){

        return new Promise((resolve,reject) => {

            axios({
                method,
                url,
                data : method == "GET" ? { } : data,
                params : method == "GET" ? data : { },
                headers,
            }).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })

        });

    }
}

const api = new Api();

export {
    api,
    server,
    prefix,
    endpoints
}