import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { MainContext,useContext } from '../Main/MainContext'
import { api,server,prefix,endpoints } from '../Main/Api';

export default function Yetkili(props) {

  const { user,token }                          = useContext(MainContext)
  const { t }                                   = useTranslation()
  const [open,setOpen]                          = useState(false);
  
  const [loading,setLoading]                    = useState(false)
  const [ara,setAra]                            = useState()
  const [kullanicilar,setKullanicilar]          = useState([])

  const { yetkili,setYetkili,rol }              = props;
  
  const afterOpenModal = () => {
    listele()
  }

  const closeModal = () => {
    setOpen(false)
  }

  const listele = () => {
    setLoading(true)
    const url = server + prefix + endpoints.kullanici_listele;
    api.request(url,"GET",{
      ara,
      hedef_rol : rol,
      alt_kullanici_listesi : true
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {      
      setKullanicilar(response.data.data);
      console.log(response.data.data)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    Modal.setAppElement('#root');
  },[])

  const araFnc = (e) => {
    if(e.key == 'Enter'){
      listele()
    }
  }

  const modalAc = () => {
    if(user.rol == "SP") return;

    setOpen(true)
  }

  return (
    <div>
        <div className="form-group">
            <label htmlFor="yetkili"><small className="text-danger">*</small> { t('yetkili') }</label>
            <input type="text" className="form-control" value={ (yetkili.ad + " " + yetkili.soyad).trim() + " (" + t(yetkili.rol) + ")" + (yetkili.id == user.id ? " (" + t("siz") + ")" : "") } readOnly onClick={() => modalAc()}/>
        </div>

        <Modal
            isOpen={ open }
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            
            <h3>{ t('yetkili_secim_ekrani') }</h3>
            <p>{ t('yetkili_secim_ekrani_bilgi') }</p>
            
            {
              loading ?
              <>
                <div className="text-center mt-5">
                  <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                  <p>{ t("yukleniyor...") }</p>
                </div>
              </>
              :
              <>
                <input type="text" value={ ara } onKeyDown={ araFnc } onChange={(e) => setAra(e.target.value)} placeholder={ t('yetkili_ara_plc') } className="form-control" />
                <div className="table-responsive mt-2">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{ t('ad_soyad') }</th>
                        <th>{ t('kullanici_kodu') }</th>
                        <th>{ t('rol') }</th>
                        <th>{ t('telefon') }</th>
                        <th>{ t('sec') }</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{ 1 }</td>
                        <td> <strong>{ user.ad + " " + user.soyad + " (" + t('kendiniz')  +")" }</strong> </td>
                        <td>{ t(user.kullanici_kodu) }</td>
                        <td>{ t(user.rol) }</td>
                        <td>{ user.telefon }</td>
                        <td>
                            <button onClick={() => { setYetkili(user); setOpen(false) }} className="btn btn-outline-success btn-sm">
                              <i className="fas fa-check"></i>
                            </button>
                        </td>
                      </tr>
                      {
                        kullanicilar.map((item,index) => (
                          <tr>
                            <td>{ index + 2 }</td>
                            <td>{ item.ad + " " + item.soyad }</td>
                            <td>{ t(item.kullanici_kodu) }</td>
                            <td>{ t(item.rol) }</td>
                            <td>{ item.telefon }</td>
                            <td>
                                <button onClick={() => { setYetkili(item); setOpen(false) }} className="btn btn-outline-success btn-sm">
                                  <i className="fas fa-check"></i>
                                </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
            }

        </Modal>
    </div>
  )
}


const customStyles = {
    content: {
      minWidth : '35%',
      minHeight : '70%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };