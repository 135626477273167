import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import Layout from '../../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { api,server,prefix,endpoints } from '../../../Main/Api';
import { useParams } from 'react-router-dom';

export default function UrinerGoruntule() {

  const { user,token }        = useContext(MainContext)
  const { t }                 = useTranslation()

  const [loading,setLoading]  = useState(false)
  const [anketler,setAnketler]= useState([])
  const { id }                = useParams()

  useEffect(() => {
    anket_sonuclari();
  },[ ])

  const anket_sonuclari = () => {
    setLoading(true)
    let url = server + prefix + endpoints.anket_sonuclari.replace(":id",id);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data } = response;

      setAnketler(data.data)

      console.log(data)
      
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  return (
    <Layout baslik={ t('anket_goruntule') }>
      {
        loading ? 
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>
          {
            (anketler.length < 1) ?
            <>
              <p>{ t('anket_henuz_yanitlanmamis') }</p>
            </>
            :
            <>
              {
                anketler.map((anket,index) => (
                  <div className="card shadow mb-3">
                    <div className="card-body">

                    <div className="table-responsive">
                      <h3>{ t('anket') + " " + (index + 1) + " - " + t('skor') + " " + anket.skor }</h3>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th className='text-center'>{ t('soru') }</th>
                            <th className='text-center'>{ t('cevap') }</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            anket.soru_ve_cevaplar.map((item,index) => (
                              <tr>
                                <td className='text-center'>{ index + 1 }</td>
                                <td className='text-center'>{ t(item.soru) }</td>
                                <td className='text-center'>{ item.cevap }</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                    </div>
                  </div>
                ))
              }
            </>
          }
        </>
      }
    </Layout>
  )
}
