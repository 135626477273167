import { useEffect, useState } from 'react'
import Navigator from './Navigator'
import ReactLoading from 'react-loading';

import { api,server,prefix,endpoints } from './Main/Api'
import { MainContext } from './Main/MainContext'
import { useTranslation } from 'react-i18next';


export default function App(){

  const [ login,setLogin ]    = useState( localStorage.getItem('login') == "true" ? true : false );
  const [ token,setToken ]    = useState(localStorage.getItem('access_token'));
  const [ user,setUser ]      = useState({ });
  const [ loading,setLoading ]= useState(false);
  const [ dil,setDil ]        = useState("tr");

  const { i18n }              = useTranslation()

  const [sidebarState,setSidebarState]  = useState(false);

  const data = {
    login,
    setLogin,

    token,
    setToken,

    user,
    setUser,

    sidebarState,
    setSidebarState,

    setLoading,
    setDil,
    dil
  }

  useEffect(() => {
    localStorage.setItem('login', login);
  },[ login ]);

  useEffect(() => {

    if(localStorage.getItem("dil") != undefined){
      setDil(localStorage.getItem("dil"))
    }

    if(login == true){

      const userData = JSON.parse(localStorage.getItem('user'));
      if(typeof userData != "object"){
        setLogin(false);
      }
      
      setLoading(true);

      const url = server + prefix + endpoints.kullanici_bilgileri;
      api.request(url,'GET',null,{
        'Authorization' : 'Bearer ' + token 
      }).then((response) => {
        
        setLoading(false);

        const { data : responseData,status } = response

        setUser(responseData.data);
        localStorage.setItem("user",JSON.stringify(responseData.data))

      }).catch((err) => {
        setLoading(false);
        const { status } = err.response;

        if(status == 401){
          setLogin(false)
          setUser(null)
          setToken(null)
          
          window.location.href = "/";
          
        }
      })
    }
  },[])

  useEffect(() => {
    i18n.changeLanguage(dil)

    localStorage.setItem("dil",dil);
  },[ dil ])

  const LoadingScreen = () => {
    return (
      <div style={ loader }>
        <ReactLoading type="cylon" color="#793FDF" />
      </div>
    )
  }

  return (
    <MainContext.Provider value={ data }>
      {
        loading ?
        <>
          <LoadingScreen/>
        </>
        :
        <>
        <Navigator/>
        </>
      }
    </MainContext.Provider>
  )
}

const loader = {
  height : '100vh',
  display : 'flex',
  alignItems : 'center',
  justifyContent : 'center'
}