import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Listele() {

  const { user,token }      = useContext(MainContext)
  const { t }               = useTranslation()
  const { kullanici_kodu }  = useParams()

  const [sayfa,setSayfa]                        = useState(1)
  const [sonSayfa,setSonSayfa]                  = useState(1)
  const [ara,setAra]                            = useState()
  const [loading,setLoading]                    = useState(false)

  const [receteler,setReceteler]                    = useState([])

  const listele = () => {
    setLoading(true)
    const url = server + prefix + endpoints.recete_listele;
    api.request(url,"GET",{
      ara,
      page : sayfa,
      kullanici_kodu
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data : responseData } = response;

      const { sayfa,son_sayfa } = responseData;
      
      setSonSayfa(son_sayfa);
      setSayfa(sayfa)

      setReceteler(responseData.data);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    listele()
  },[sayfa])

  const ileri = () => {
    if(sayfa >= sonSayfa) return;
    
    const currentPage = sayfa;
    setSayfa(currentPage + 1)
  }

  const geri = () => {
    if(sayfa < 2) return;

    const currentPage = sayfa
    setSayfa(currentPage - 1);
  }

  return (
    <div>
      {
        loading ?
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>
        <div className="table-responsive mt-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>{ t('tp_adi') }</th>
                <th className='text-center'>{ t('genel_recete') }</th>
                <th className='text-center'>{ t('olusturulma_tarihi') }</th>
                <th className='text-center'>{ t('goruntule') }</th>
              </tr>
            </thead>
            <tbody>
              {
                receteler.map((item,index) => (
                  <tr>
                    <td className='text-center'>{ index + 1 }</td>
                    <td className='text-center'>{ item.recete_adi }</td>
                    <td className='text-center'>{ item.genel ? t("EVET") : t("HAYIR") }</td>
                    <td className='text-center'>{ item.olusturulma_tarihi }</td>
                    <td className='text-center'>
                      <Link to={ "/tedavi-protokolu/goruntule/" + item.id }>
                        <button className="btn btn-success">
                          <i className="fas fa-eye"></i> { t("goruntule") }
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="text-right">
            <button onClick={() => geri()} className="btn btn-dark mr-1" disabled={ sayfa < 2 ? true : false }>
              <i className="fas fa-arrow-left"></i>
            </button>
            <span>{ sayfa + "/" + sonSayfa }</span>
            <button onClick={() => ileri()} className="btn btn-dark ml-2" disabled={ sayfa >= sonSayfa ? true : false }>
              <i className="fas fa-arrow-right"></i>
            </button>
        </div>
        </>
      }
    
    </div>
  )
}
