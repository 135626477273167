import React, { useEffect,useState } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import ulkeler from '../../metinler/ulkeler.json'

import Yetkili from '../../Components/Yetkili'
import { api,server,prefix,endpoints } from '../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Duzenle() {
  const { user,token }  = useContext(MainContext)
  const { kullanici_kodu : param_kk }       = useParams()

  const navigate                            = useNavigate()
  const { t }                               = useTranslation();

  const [kullaniciX,setKullaniciX]          = useState()
  const [yetkili,setYetkili]                = useState(user);

  const [ad,setAd]                          = useState();
  const [soyad,setSoyad]                    = useState();
  const [dogumTarihi,setDogumTarihi]        = useState();
  const [kk,setKK]                          = useState();
  const [ozetBilgiler,setOzetBilgiler]      = useState();
  const [telefon,setTelefon]                = useState();
  const [email,setEmail]                    = useState();
  const [ulke,setUlke]                      = useState();
  const [sehir,setSehir]                    = useState();
  const [cinsiyet,setCinsiyet]              = useState();

  const [telefonKodu,setTelefonKodu]        = useState("+")
  const [ulkeKodu,setUlkeKodu]              = useState("")
  const [dilKodu,setDilKodu]                = useState("")
  const [saatDilimi,setSaatDilimi]          = useState()
  const [sehirler,setSehirler]              = useState([])
  const [loading,setLoading]                = useState(false)

  /* Hasta Bilgileri */
  const [cihazSeriNo, setCihazSeriNo] = useState();
  const [boy, setBoy] = useState();
  const [kilo, setKilo] = useState();
  const [medeniHal, setMedeniHal] = useState();
  const [meslek, setMeslek] = useState();
  const [sonAdetTarihi, setSonAdetTarihi] = useState();
  const [menapozSure, setMenapozSure] = useState();
  const [sikayeti, setSikayeti] = useState();
  const [idrarInkontinansOykusu, setIdrarInkontinansOykusu] = useState();
  const [dogumSayisiVeSekli, setDogumSayisiVeSekli] = useState();
  const [dogumKomplikasyonlari, setDogumKomplikasyonlari] = useState();
  const [gecirdigiOperasyonlar, setGecirdigiOperasyonlar] = useState();
  const [kullandigiIlaclar, setKullandigiIlaclar] = useState();
  const [iriDogumOykusu, setIriDogumOykusu] = useState();
  const [urogram, setUrogram] = useState();
  const [urodinamikTetkik, setUrodinamikTetkik] = useState();
  const [sigaraBilgi, setSigaraBilgi] = useState();
  const [qTipiTesti, setQTipiTesti] = useState();
  const [padTesti, setPadTesti] = useState();
  const [residuIdrarVolumu, setResiduIdrarVolumu] = useState();
  const [tvUsg, setTvUsg] = useState();
  const [bmi, setBmi] = useState();
  const [vajinalDogum, setVajinalDogum] = useState();
  const [sezeryen, setSezeryen] = useState();
  const [hormonReplasmanTedavisi, setHormonReplasmanTedavisi] = useState();
  const [sigaraKullanim, setSigaraKullanim] = useState();
  const [alkolKullanim, setAlkolKullanim] = useState();
  const [cinselAktif, setCinselAktif] = useState();
  const [jinekolojikHastalik, setJinekolojikHastalik] = useState();
  const [koah, setKoah] = useState();
  const [kalpHastaligi, setKalpHastaligi] = useState();
  const [renalHastalik, setRenalHastalik] = useState();
  const [kabizlik, setKabizlik] = useState();
  const [diyabet, setDiyabet] = useState();
  const [hipertansiyon, setHipertansiyon] = useState();
  const [kognitifBozukluk, setKognitifBozukluk] = useState();
  const [rekurenIye, setRekurenIye] = useState();
  const [prolapsus, setProlapsus] = useState();
  const [botoks, setBotoks] = useState();
  const [sns, setSns] = useState();
  const [ptns, setPtns] = useState();
  const [oncekiAamTedavisi, setOncekiAamTedavisi] = useState();
  const [dm, setDm] = useState();
  const [ht, setHt] = useState();
  const [ikab, setIkab] = useState();
  /* Hasta Bilgileri */

  const [contentLoading, setContentLoading]         = useState(false);
  const [arsivIslem,setArsivIslem]                  = useState()
  const [arsivLoading,setArsivLoading]              = useState(false)
  const [parolaSifirlaniyor,setParolaSifirlaniyor]  = useState(false)

  const [hemenSilIslem,setHemenSillIslem]   = useState()
  const [hemenSilLoading,setHemenSilLoading]= useState(false)

  const hemen_sil = (kk) => {

    setHemenSillIslem(kk);
    setHemenSilLoading(true);

    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setHemenSilLoading(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_sil.replace(":id",kk);
        api.request(url,"DELETE",{ hemen_sil : true },{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          if(!hata){
            navigate("/kullanici/listele")
          }

        });

      }
    })
  }

  const ulkeSec = (ulke) => {

    if(ulke == ""){
      setSehirler([])
      return;
    }

    setUlke(ulke);
    setSehir("");

    const secilenUlke = ulkeler.find(x => x.adi == ulke);

    setTelefonKodu(secilenUlke.telefon_kodu)
    setSaatDilimi(secilenUlke.saat_dilimi)
    setUlkeKodu(secilenUlke.kod)
    setDilKodu(secilenUlke.dil_kodu)

    setSehirler(secilenUlke.sehirler);
  }

  const guncelle = () => {
    setLoading(true);

    const formData = {
      kullanici_kodu : kk,
      ad,
      soyad,
      cinsiyet,
      dogum_tarihi : dogumTarihi,
      email,
      ulke_kodu : ulkeKodu,
      dil_kodu : dilKodu,
      saat_dilimi : saatDilimi,
      telefon_kodu : telefonKodu,
      telefon,
      ozet_bilgiler : ozetBilgiler,
      ulke,
      sehir,
      yetkili_id : yetkili.id,

      cihaz_seri_no : cihazSeriNo,
      boy : boy,
      kilo : kilo,
      medeni_hal : medeniHal,
      meslek : meslek,
      son_adet_tarihi : sonAdetTarihi,
      menapoz_sure : menapozSure,
      sikayeti : sikayeti,
      idrar_inkontinans_oykusu : idrarInkontinansOykusu,
      dogum_sayisi_ve_sekli : dogumSayisiVeSekli,
      dogum_komplikasyonlari : dogumKomplikasyonlari,
      gecirdigi_operasyonlar : gecirdigiOperasyonlar,
      kullandigi_ilaclar : kullandigiIlaclar,
      iri_dogum_oykusu : iriDogumOykusu,
      urogram : urogram,
      urodinamik_tetkik : urodinamikTetkik,
      sigara_bilgi : sigaraBilgi,
      q_tipi_testi : qTipiTesti,
      pad_testi : padTesti,
      residu_idrar_volumu : residuIdrarVolumu,
      tv_usg : tvUsg,
      bmi : bmi,
      vajinal_dogum : vajinalDogum,
      sezeryen : sezeryen,
      hormon_replasman_tedavisi : hormonReplasmanTedavisi,
      sigara_kullanim : sigaraKullanim,
      alkol_kullanim : alkolKullanim,
      cinsel_aktif : cinselAktif,
      jinekolojik_hastalik : jinekolojikHastalik,
      koah : koah,
      kalp_hastaligi : kalpHastaligi,
      renal_hastalik : renalHastalik,
      kabizlik : kabizlik,
      diyabet : diyabet,
      hipertansiyon : hipertansiyon,
      kognitif_bozukluk : kognitifBozukluk,
      rekuren_iye : rekurenIye,
      prolapsus : prolapsus,
      botoks : botoks,
      sns : sns,
      ptns : ptns,
      onceki_aam_tedavisi : oncekiAamTedavisi,
      dm : dm,
      ht : ht,
      ikab : ikab,
    }

    let url = server + prefix + endpoints.hasta_guncelle.replace(":id",kk);
    api.request(url,"PUT",formData,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      setLoading(false);
      const { data : responseData } = response;
      
      const { hata,mesaj } = responseData;
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam')
    }).then((x) => {
        if(x.isConfirmed){
            if(!hata && param_kk != kk){
                navigate('/kullanici/duzenle/' + kk)
            }
        }
    })

    }).catch((err) => {
      setLoading(false);
    })
  }

  const parola_sifirla = () => {
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t("islemi_onayla"),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      if(x.isConfirmed){
        sifirla()
      }
    })
  }

  const sifirla = () => {
    setParolaSifirlaniyor(true);

    let url = server + prefix + endpoints.parola_gonder.replace(":id",kk);
    api.request(url,"POST",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { hata, mesaj } = response.data;

      setParolaSifirlaniyor(false);
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam'),
      })
    });
  }

  const arsivden_cikar = (kk) => {

    setArsivIslem(kk);
    setArsivLoading(true);

    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setArsivLoading(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_arsivden_cikar.replace(":id",kk);
        api.request(url,"POST",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          if(!hata){
            const kullaniciY = { ...kullaniciX };
            kullaniciY.arsive_alindi = false;
            setKullaniciX(kullaniciY);
          }

        });

      }
    })
  }

  useEffect(() => {

    setContentLoading(true);
    let url = server + prefix + endpoints.hasta_bilgi.replace(":id",param_kk);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      
      const { data : responseData } = response;
      const { hata, mesaj } = responseData;

      if(hata){
        MySwal.fire({
          allowOutsideClick : false,
          allowEscapeKey : false,
          titleText : mesaj,
          confirmButtonText : t('tamam')
        }).then((x) => {
          navigate("/dashboard")
      })

      }else{

        setContentLoading(false);
        const { data } = responseData;

        setKullaniciX(data.kullanici);
        setYetkili({ id : data.kullanici.yetkili_id, rol : data.kullanici.yetkili_rolu, ad : data.kullanici.yetkili_adi, soyad : "" })
        
        setAd(data.kullanici.ad);
        setSoyad(data.kullanici.soyad);
        setCinsiyet(data.kullanici.cinsiyet);
        setKK(data.kullanici.kullanici_kodu);
        setOzetBilgiler(data.kullanici.ozet_bilgiler);
        setTelefon(data.kullanici.telefon);
        setEmail(data.kullanici.email);
        ulkeSec(data.kullanici.ulke,false)
        setSehir(data.kullanici.sehir);
        setDogumTarihi(data.kullanici.dogum_tarihi);

        setCihazSeriNo(data.hasta.cihaz_seri_no)
        setBoy(data.hasta.boy)
        setKilo(data.hasta.kilo)
        setMedeniHal(data.hasta.medeni_hal)
        setMeslek(data.hasta.meslek)
        setSonAdetTarihi(data.hasta.son_adet_tarihi)
        setMenapozSure(data.hasta.menapoz_sure)
        setSikayeti(data.hasta.sikayeti)
        setIdrarInkontinansOykusu(data.hasta.idrar_inkontinans_oykusu)
        setDogumSayisiVeSekli(data.hasta.dogum_sayisi_ve_sekli)
        setDogumKomplikasyonlari(data.hasta.dogum_komplikasyonlari)
        setGecirdigiOperasyonlar(data.hasta.gecirdigi_operasyonlar)
        setKullandigiIlaclar(data.hasta.kullandigi_ilaclar)
        setIriDogumOykusu(data.hasta.iri_dogum_oykusu)
        setUrogram(data.hasta.urogram)
        setUrodinamikTetkik(data.hasta.urodinamik_tetkik)
        setSigaraBilgi(data.hasta.sigara_bilgi)
        setQTipiTesti(data.hasta.q_tipi_testi)
        setPadTesti(data.hasta.pad_testi)
        setResiduIdrarVolumu(data.hasta.residu_idrar_volumu)
        setTvUsg(data.hasta.tv_usg)
        setBmi(data.hasta.bmi)
        setVajinalDogum(data.hasta.vajinal_dogum)
        setSezeryen(data.hasta.sezeryen)
        setHormonReplasmanTedavisi(data.hasta.hormon_replasman_tedavisi)
        setSigaraKullanim(data.hasta.sigara_kullanim)
        setAlkolKullanim(data.hasta.alkol_kullanim)
        setCinselAktif(data.hasta.cinsel_aktif)
        setJinekolojikHastalik(data.hasta.jinekolojik_hastalik)
        setKoah(data.hasta.koah)
        setKalpHastaligi(data.hasta.kalp_hastaligi)
        setRenalHastalik(data.hasta.renal_hastalik)
        setKabizlik(data.hasta.kabizlik)
        setDiyabet(data.hasta.diyabet)
        setHipertansiyon(data.hasta.hipertansiyon)
        setKognitifBozukluk(data.hasta.kognitif_bozukluk)
        setRekurenIye(data.hasta.rekuren_iye)
        setProlapsus(data.hasta.prolapsus)
        setBotoks(data.hasta.botoks)
        setSns(data.hasta.sns)
        setPtns(data.hasta.ptns)
        setOncekiAamTedavisi(data.hasta.onceki_aam_tedavisi)
        setDm(data.hasta.dm)
        setHt(data.hasta.ht)
        setIkab(data.hasta.ikab)

      }

    }).catch((err) => {
      setContentLoading(false);
    })

  },[ ])

  return (
    <Layout baslik={ t('hasta_duzenle') }>
        <div className="row">
          <div className="col-md-8">

            {
              (kullaniciX != undefined && kullaniciX.arsive_alindi) ?
              <>
              <div className="alert alert-danger">
                <p>{ t('arsive_alinan_kullanici') }</p>
                <p>{ t('arsive_alinma_tarihi') } : { kullaniciX.arsive_alinma_tarihi }</p>

                <button onClick={() => arsivden_cikar(kullaniciX.kullanici_kodu)} className="btn btn-outline-dark">
                  {
                    (arsivLoading && arsivIslem == kullaniciX.kullanici_kodu) ?
                    <>
                      <i className="fas fa-spinner fa-spin"></i>
                    </>
                    :
                    <>
                      <i className="fas fa-reply-all"></i> { t('arsivden_cikar') }
                    </>
                  }
                </button>

                {
                  user.rol == "ADMIN" &&
                  <>
                  <button onClick={() => hemen_sil(kullaniciX.kullanici_kodu)} className="btn btn-outline-dark ml-1">
                    {
                      (hemenSilLoading && hemenSilIslem == kullaniciX.kullanici_kodu) ?
                      <>
                        <i className="fas fa-spinner fa-spin"></i>
                      </>
                      :
                      <>
                        <i className="fas fa-trash-alt"></i> { t('hemen_sil') }
                      </>
                    }
                  </button>
                  </>
                }
              </div>
              </>
              :
              <></>
            }


            <div className="card shadow">
                {
                    contentLoading ?
                    <>
                        <div className="text-center mt-5">
                        <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                        <p>{ t("yukleniyor...") }</p>
                        </div>
                    </>
                    :
                    <>
                        <div className="card-body">

                            <h3 className='mb-3'>{ t("genel_bilgiler") }</h3>

                            <div className="row">
                                <div className="col-md-6">

                                <Yetkili yetkili={ yetkili } setYetkili={ setYetkili } rol={ "HASTA" }/>

                                <div className="row">
                                    <div className="col-md-6">

                                    <div className="form-group">
                                        <label htmlFor="ad"> <small className="text-danger">*</small> { t('ad') }</label>
                                        <input id="ad" type="text" className="form-control" value={ ad } onChange={(e) => setAd(e.target.value)} />
                                    </div>

                                    </div>
                                    <div className="col-md-6">

                                    <div className="form-group">
                                        <label htmlFor="soyad"><small className="text-danger">*</small> { t('soyad') }</label>
                                        <input id="soyad" type="text" className="form-control" value={ soyad } onChange={(e) => setSoyad(e.target.value)} />
                                    </div>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="cinsiyet"><small className="text-danger">*</small> { t('cinsiyet') }</label>
                                    <select defaultValue="" name="cinsiyet" id="cinsiyet" className="form-control" value={ cinsiyet } onChange={ (e) => setCinsiyet(e.target.value) }>
                                    <option value="" disabled>{ t("secim_yapilmadi") }</option>
                                    <option value="KADIN">{ t('KADIN') }</option>
                                    <option value="ERKEK">{ t('ERKEK') }</option>
                                    </select>
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="dogumTarihi">{ t('dogum_tarihi') }</label>
                                    <input id="dogumTarihi" type="date" className="form-control" value={ dogumTarihi } onChange={(e) => setDogumTarihi(e.target.value)} autoComplete="new-password"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kk"><small className="text-danger">*</small> { t('kullanici_kodu') }</label>
                                    <input id="kk" type="text" className="form-control" value={ kk } onChange={(e) => setKK(e.target.value)} autoComplete="new-password"/>
                                </div>

                                </div>

                                <div className="col-md-6">

                                <div className="form-group">
                                    <label htmlFor="ulke"><small className="text-danger">*</small> { t('ulke') }</label>
                                    <select defaultValue="" name="ulke" id="ulke" className="form-control" value={ ulke } onChange={ (e) => ulkeSec(e.target.value) }>
                                    <option value="" disabled>{ t("secim_yapilmadi") }</option>
                                    {
                                        ulkeler.map(ulke => (
                                        <option key={ ulke.adi } value={ ulke.adi }>{ ulke.adi }</option>
                                        ))
                                    }
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sehir"><small className="text-danger">*</small> { t('sehir') }</label>
                                    <select defaultValue="" name="sehir" id="sehir" className="form-control" value={ sehir } onChange={(e) => setSehir(e.target.value)}>
                                    <option value="" disabled>{ t("secim_yapilmadi") }</option>
                                    {
                                        sehirler.map(sehir => (
                                        <option key={ sehir } value={ sehir }>{ sehir }</option>
                                        ))
                                    }
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="telefon"><small className="text-danger">*</small> { t("telefon_numarasi") }</label>
                                    <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="telefon">{ telefonKodu }</span>
                                    </div>
                                    <input id="telefon" type="text" className="form-control" value={ telefon } onChange={(e) => setTelefon(e.target.value)} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">{ t('email') }</label>
                                    <input id="email" type="text" className="form-control" value={ email } onChange={(e) => setEmail(e.target.value)} />
                                </div>

                                </div>

                                <div className="col-md-12">

                                <div className="form-group">
                                    <label htmlFor="cihaz_seri_no"><small className="text-danger">*</small> { t('cihaz_seri_no') }</label>
                                    <input id="cihaz_seri_no" value={ cihazSeriNo } onChange={(e) => setCihazSeriNo(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ozetBilgiler">{ t('ozet_bilgiler') }</label>
                                    <textarea id="ozetBilgiler" rows={ 3 } type="text" className="form-control" value={ ozetBilgiler } onChange={(e) => setOzetBilgiler(e.target.value)}></textarea>
                                </div>

                                </div>
                            </div>

                            <h3 className='mb-3'>{ t("hastanin_oykusu") }</h3>
                            <div className="row">
                                <div className="col-md-6">

                                <div className="form-group">
                                    <label htmlFor="boy">{ t('boy') }</label>
                                    <input id="boy" value={ boy } onChange={(e) => setBoy(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kilo">{ t('kilo') }</label>
                                    <input id="kilo" value={ kilo } onChange={(e) => setKilo(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="menapoz_sure">{ t('menapoz_sure') }</label>
                                    <input id="menapoz_sure" value={ menapozSure } onChange={(e) => setMenapozSure(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="q_tipi_testi">{ t('q_tipi_testi') }</label>
                                    <input id="q_tipi_testi" value={ qTipiTesti } onChange={(e) => setQTipiTesti(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="pad_testi">{ t('pad_testi') }</label>
                                    <input id="pad_testi" value={ padTesti } onChange={(e) => setPadTesti(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="tv_usg">{ t('tv_usg') }</label>
                                    <input id="tv_usg" value={ tvUsg } onChange={(e) => setTvUsg(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="bmi">{ t('bmi') }</label>
                                    <input id="bmi" value={ bmi } onChange={(e) => setBmi(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="residu_idrar_volumu">{ t('residu_idrar_volumu') }</label>
                                    <input id="residu_idrar_volumu" value={ residuIdrarVolumu } onChange={(e) => setResiduIdrarVolumu(e.target.value)} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sikayeti">{ t('sikayeti') }</label>
                                    <textarea id="sikayeti" value={ sikayeti == null ? '' : sikayeti } onChange={(e) => setSikayeti(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="idrar_inkontinans_oykusu">{ t('idrar_inkontinans_oykusu') }</label>
                                    <textarea id="idrar_inkontinans_oykusu" value={ idrarInkontinansOykusu == null ? '' : idrarInkontinansOykusu } onChange={(e) => setIdrarInkontinansOykusu(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dogum_sayisi_ve_sekli">{ t('dogum_sayisi_ve_sekli') }</label>
                                    <textarea id="dogum_sayisi_ve_sekli" value={ dogumSayisiVeSekli == null ? '' : dogumSayisiVeSekli } onChange={(e) => setDogumSayisiVeSekli(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="gecirdigi_operasyonlar">{ t('gecirdigi_operasyonlar') }</label>
                                    <textarea id="gecirdigi_operasyonlar" value={ gecirdigiOperasyonlar == null ? '' : gecirdigiOperasyonlar } onChange={(e) => setGecirdigiOperasyonlar(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kullandigi_ilaclar">{ t('kullandigi_ilaclar') }</label>
                                    <textarea id="kullandigi_ilaclar" value={ kullandigiIlaclar == null ? '' : kullandigiIlaclar } onChange={(e) => setKullandigiIlaclar(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="iri_dogum_oykusu">{ t('iri_dogum_oykusu') }</label>
                                    <textarea id="iri_dogum_oykusu" value={ iriDogumOykusu == null ? '' : iriDogumOykusu } onChange={(e) => setIriDogumOykusu(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="urogram">{ t('urogram') }</label>
                                    <textarea id="urogram" value={ urogram == null ? '' : urogram } onChange={(e) => setUrogram(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="urodinamik_tetkik">{ t('urodinamik_tetkik') }</label>
                                    <textarea id="urodinamik_tetkik" value={ urodinamikTetkik == null ? '' : urodinamikTetkik } onChange={(e) => setUrodinamikTetkik(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sigara_bilgi">{ t('sigara_bilgi') }</label>
                                    <textarea id="sigara_bilgi" value={ sigaraBilgi == null ? '' : sigaraBilgi } onChange={(e) => setSigaraBilgi(e.target.value)} type="text" className="form-control"></textarea>
                                </div>

                                </div>

                                <div className="col-md-6">

                                <div className="form-group">
                                    <label htmlFor="son_adet_tarihi">{ t('son_adet_tarihi') }</label>
                                    <input id="son_adet_tarihi" value={ sonAdetTarihi } onChange={(e) => setSonAdetTarihi(e.target.value)} type="date" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="medeni_hal">{ t('medeni_hal') }</label>
                                    <select id="medeni_hal" defaultValue="" value={ medeniHal } onChange={(e) => setMedeniHal(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="BEKAR">{ t("BEKAR") }</option>
                                    <option value="EVLI">{ t("EVLI") }</option>
                                    <option value="DUL">{ t("DUL") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="meslek">{ t('meslek') }</label>
                                    <select id="meslek" defaultValue="" value={ meslek } onChange={(e) => setMeslek(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="CALISIYOR">{ t("CALISIYOR") }</option>
                                    <option value="CALISMIYOR">{ t("CALISMIYOR") }</option>
                                    <option value="EMEKLI">{ t("EMEKLI") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="vajinal_dogum">{ t('vajinal_dogum') }</label>
                                    <select id="vajinal_dogum" defaultValue="" value={ vajinalDogum } onChange={(e) => setVajinalDogum(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sezeryen">{ t('sezeryen') }</label>
                                    <select id="sezeryen" defaultValue="" value={ sezeryen } onChange={(e) => setSezeryen(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="hormon_replasman_tedavisi">{ t('hormon_replasman_tedavisi') }</label>
                                    <select id="hormon_replasman_tedavisi" defaultValue="" value={ hormonReplasmanTedavisi } onChange={(e) => setHormonReplasmanTedavisi(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sigara_kullanim">{ t('sigara_kullanim') }</label>
                                    <select id="sigara_kullanim" defaultValue="" value={ sigaraKullanim } onChange={(e) => setSigaraKullanim(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="alkol_kullanim">{ t('alkol_kullanim') }</label>
                                    <select id="alkol_kullanim" defaultValue="" value={ alkolKullanim } onChange={(e) => setAlkolKullanim(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="cinsel_aktif">{ t('cinsel_aktif') }</label>
                                    <select id="cinsel_aktif" defaultValue="" value={ cinselAktif } onChange={(e) => setCinselAktif(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="jinekolojik_hastalik">{ t('jinekolojik_hastalik') }</label>
                                    <select id="jinekolojik_hastalik" defaultValue="" value={ jinekolojikHastalik } onChange={(e) => setJinekolojikHastalik(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="koah">{ t('koah') }</label>
                                    <select id="koah" defaultValue="" value={ koah } onChange={(e) => setKoah(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kalp_hastaligi">{ t('kalp_hastaligi') }</label>
                                    <select id="kalp_hastaligi" defaultValue="" value={ kalpHastaligi } onChange={(e) => setKalpHastaligi(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="renal_hastalik">{ t('renal_hastalik') }</label>
                                    <select id="renal_hastalik" defaultValue="" value={ renalHastalik } onChange={(e) => setRenalHastalik(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kabizlik">{ t('kabizlik') }</label>
                                    <select id="kabizlik" defaultValue="" value={ kabizlik } onChange={(e) => setKabizlik(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="diyabet">{ t('diyabet') }</label>
                                    <select id="diyabet" defaultValue="" value={ diyabet } onChange={(e) => setDiyabet(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="hipertansiyon">{ t('hipertansiyon') }</label>
                                    <select id="hipertansiyon" defaultValue="" value={ hipertansiyon } onChange={(e) => setHipertansiyon(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="kognitif_bozukluk">{ t('kognitif_bozukluk') }</label>
                                    <select id="kognitif_bozukluk" defaultValue="" value={ kognitifBozukluk } onChange={(e) => setKognitifBozukluk(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="rekuren_iye">{ t('rekuren_iye') }</label>
                                    <select id="rekuren_iye" defaultValue="" value={ rekurenIye } onChange={(e) => setRekurenIye(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="prolapsus">{ t('prolapsus') }</label>
                                    <select id="prolapsus" defaultValue="" value={ prolapsus } onChange={(e) => setProlapsus(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="botoks">{ t('botoks') }</label>
                                    <select id="botoks" defaultValue="" value={ botoks } onChange={(e) => setBotoks(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sns">{ t('sns') }</label>
                                    <select id="sns" defaultValue="" value={ sns } onChange={(e) => setSns(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ptns">{ t('ptns') }</label>
                                    <select id="ptns" defaultValue="" value={ ptns } onChange={(e) => setPtns(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="onceki_aam_tedavisi">{ t('onceki_aam_tedavisi') }</label>
                                    <select id="onceki_aam_tedavisi" defaultValue="" value={ oncekiAamTedavisi } onChange={(e) => setOncekiAamTedavisi(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dm">{ t('dm') }</label>
                                    <select id="dm" defaultValue="" value={ dm } onChange={(e) => setDm(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ht">{ t('ht') }</label>
                                    <select id="ht" defaultValue="" value={ ht } onChange={(e) => setHt(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="1">{ t("EVET") }</option>
                                    <option value="0">{ t("HAYIR") }</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ikab">{ t('ikab') }</label>
                                    <select id="ikab" defaultValue="" value={ ikab } onChange={(e) => setIkab(e.target.value)} className="form-control">
                                    <option value="">{ t("secim_yapilmadi") }</option>
                                    <option value="NORMAL">{ t("NORMAL") }</option>
                                    <option value="ANORMAL">{ t("ANORMAL") }</option>
                                    </select>
                                </div>

                                </div>
                            </div>

                            <button onClick={() => parola_sifirla()} className="btn btn-danger mb-2">
                                {
                                parolaSifirlaniyor ? 
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                                </>
                                :
                                <>
                                    <i className="fas fa-lock"></i> { t("parola_sifirla") }
                                </>
                                }
                            </button>

                            <button className="btn btn-dark btn-block btn-lg" onClick={() => guncelle()}>
                                {
                                loading ? 
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                                </>
                                :
                                <>
                                    <i className="fas fa-check"></i> { t("guncelle") }
                                </>
                                }
                            </button>

                        </div>
                    </>
                }
            </div>

          </div>
        </div>
    </Layout>
  )
}
