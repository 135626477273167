export default {
    translation : {
        "kullanici_kodu": "Kullanıcı Kodu",
        "parola": "Parola",
        "giris_yap": "Giriş Yap",
        "isleniyor": "İşleniyor...",
        "tamam": "Tamam",
        "adminler": "Adminler",
        "sub_adminler": "SubAdminler",
        "spler": "Sağlık Profesyonelleri",
        "hastalar": "Hastalar",
        "kullanicilar": "Kullanıcılar",
        "receteler": "Reçeteler",
        "recete_olustur": "Reçete Oluştur",
        "receteleri_goruntule": "Reçeteleri Görüntüle",
        "recete_ayarlari": "Reçete Ayarları",
        "yetkiler": "Yetkiler",
        "cikis_yap": "Çıkış Yap",
        "yeni_olustur": "Yeni Oluştur",
        "kullanici_olustur": "Kullanıcı Oluştur",
        "ad": "Ad",
        "soyad":"Soyad",
        "ozet_bilgiler": "Özet Bilgiler",
        "email":"Email",
        "telefon_numarasi":"Telefon Numarası",
        "ulke":"Ülke",
        "sehir":"Şehir",
        "secim_yapilmadi":"Seçim Yapılmadı",
        "kayit_tarihi":"Kayıt Tarihi",
        "duzenle":"Düzenle",
        "sil":"Sil",
        "ara":"Ara",
        "kullanici_ara_placeholder":"Ad, soyad, kullanıcı kodu, email adresi veya telefon numarası ile arama yapabilirsiniz.",
        "kullanici_goruntule":"Kullanıcı Görüntüle",
        "guncelle":"Güncelle",
        "parola_bilgilendirme" : "Değiştirmek istemiyorsanız boş bırakın.",
        "silme_islemini_onayla" : "Silme işlemini onaylıyor musunuz?",
        "vazgec" : "Vazgeç",
        "aksiyon" : "Aksiyon",
        "arsivden_cikar":"Arşivden Çıkar",
        "islemi_onayla" : "Devam etmek istiyor musunuz?",
        "arsive_alinan_kullanici":"Bu kullanıcı arşive alınmış ve sistemden silinmek üzere. Arşive alınan kullanıcılar arşive alınma tarihinden itibaren 30 gün sonrar sistemden otomatikmen silinir.",
        "arsive_alinma_tarihi" : "Arşive alınma tarihi",
        "rol":"Rol",
        "ADMIN" : "Admin",
        "SUBADMIN" : "SubAdmin",
        "SP" : "Sağlık Profesyoneli",
        "HASTA" : "Hasta",
        "filtrele":"Filtrele",
        "belirtilmedi" : "Belirtilmedi",
        "evet":"Evet",
        "hayir":"Hayır",
        "arsiv":"Arşiv",
        "hepsi":"Hepsi",
        "kriter":"Kriter",
        "en_az":"En Az",
        "en_fazla":"En Fazla",
        "tedavi_suresi_dk":"Tedavi süresi (dakika)",
        "hafta_sayisi":"Hafta sayısı",
        "haftadaki_siklik":"Haftadaki sıklık",
        "darbe_genisligi":"Darbe genişliği",
        "frekans":"Frekans",
        "mikro_sn" : "Mikro saniye",
        "parola_sms_olacak_gonderilecek" : "Parola sms olarak gönderilecek.",
        "KADIN":"Kadın",
        "ERKEK":"Erkek",
        "cinsiyet":"Cinsiyet",
        "parola_sifirla" : "Parola Sıfırla (SMS Olarak Gönderilir)",
        "hasta_olustur":"Hasta Oluştur",
        "dogum_tarihi":"Doğum Tarihi",
        "genel_bilgiler" : "Genel Bilgiler",
        "hastanin_oykusu" : "Hastanın Öyküsü",
        "cihaz_seri_no" : "Cihaz Seri No",
        "boy":"Boy",
        "kilo":"Kilo",
        "menapoz_sure":"Menapoz Süresi",
        "q_tipi_testi":"Q Tipi Testi",
        "pad_testi":"Pad Testi",
        "tv_usg":"TV USG",
        "bmi":"BMI",
        "residu_idrar_volumu":"Residu İdrar Volumu",
        "sikayeti":"Şikayeti",
        "idrar_inkontinans_oykusu":"İdrar İnkontinan Öyküsü",
        "dogum_sayisi_ve_sekli":"Doğum Sayısı ve Şekli",
        "gecirdigi_operasyonlar":"Geçirdiği Operasyonlar",
        "kullandigi_ilaclar":"Kullandığı İlaçlar",
        "iri_dogum_oykusu":"İri Doğum Öyküsü",
        "urogram":"Urogram",
        "urodinamik_tetkik":"Ürodinamik Tetkik",
        "sigara_bilgi":"Sigara Bilgi",
        "son_adet_tarihi":"Son Adet Tarihi",
        "medeni_hal":"Medeni Hal",
        "meslek":"Meslek",
        "vajinal_dogum":"Vajinal Doğum",
        "sezeryen":"Sezeryen",
        "hormon_replasman_tedavisi":"Hormon Replasman Tedavisi",
        "sigara_kullanim":"Sigara Kullanım",
        "alkol_kullanim":"Alkol Kullanım",
        "cinsel_aktif":"Cinsel Aktif",
        "jinekolojik_hastalik":"Jinekolojik Hastalık",
        "koah":"Koah",
        "kalp_hastaligi":"Kalp Hastalığı",
        "renal_hastalik":"Renal Hastalık",
        "kabizlik":"Kabızlık",
        "diyabet":"Diyabet",
        "hipertansiyon":"Hipertansiyon",
        "kognitif_bozukluk":"Kognitif Bozukluk",
        "rekuren_iye":"Rekuren İye",
        "prolapsus":"Prolapsus",
        "botoks":"Botoks",
        "sns":"SNS",
        "ptns":"PTNS",
        "onceki_aam_tedavisi":"Önceki AAM Tedavisi",
        "dm":"DM",
        "ht":"HT",
        "ikab":"IKAB",
        "BEKAR":"Bekar",
        "EVLI":"Evli",
        "DUL":"Dul",
        "CALISIYOR":"Çalışıyor",
        "CALISMIYOR":"Çalışmıyor",
        "EMEKLI":"Emekli",
        "NORMAL":"Normal",
        "ANORMAL":"Anormal",
        "hasta_goruntule" : "Hasta Görüntüle",
        "sozlesme_kabul_edilmedi" : "Giriş yapabilmek için KVKK sözleşmelerini kabul etmelisiniz.",
        "parolami_unuttum" : "Parolamı Unuttum",
        "telefon_son_4" : "Telefon Numaranızın Son 4 Hanesi",
        "parola_sifirla" : "Parola Sıfırla",
        "gonder" : "Gönder",
        "tedavi_protokolu" : "Tedavi Protokolü",
        "frekans_hz" : "Frekans (Hz)",
        "dbg_mikro_sn" : "Darbe Genişliği (Mikro saniye)",
        "SAG" : "Sağ ayak",
        "SOL" : "Sol ayak",
        "CIFT": "Çift ayak",
        "ayak": "Ayak",
        "recete_adi": "Reçete Adı",
        "genel_recete": "Genel Reçete (Herkes Kullanabilir)",
        "yetkili_secim_ekrani" : "Yetkili Seçim Ekranı",
        "yetkili_secim_ekrani_bilgi" : "Bu ekranda yetkilisi olduğunuz alt kullanıcılarınızı görüntüleyebilir ve seçebilirsiniz.",
        "yetkili": "Yetkili",
        "kendiniz" : "Kendiniz",
        "yetkili_ara_plc" : "Ad, soyad, email, telefon ve kullanıcı koduna göre arama yapabilirsiniz.",
        "yukleniyor..." : "Yükleniyor...",
        "yukleniyor" : "Yükleniyor...",
        "ad_soyad" : "Ad Soyad",
        "sec" : "Seç",
        "telefon" : "Telefon",
        "siz" : "Siz",
        "seans_durumu" : "Seans Durumu",
        "tedavi_durumu" : "Tedavi Durumu",
        "unvan" : "Ünvan",
        "hastane" : "Hastane",
        "hemen_sil" : "Hemen Sil",
        "hasta_bilgileri" : "HASTA BİLGİLERİ",
        "tedavi_bilgileri" : "TEDAVİ BİLGİLERİ",
        "hasta_duzenle" : "Hasta Düzenle",
        "goruntule":"Görüntüle",
        "olusturulma_tarihi" : "Oluşturulma Tarihi",
        "recete_duzenle":"Reçete Düzenle",
        "listele" : "Listele",
        "ayarlar" : "Ayarlar",
        "tp_duzenle" : "Tedavi Protokolü Düzenle",
        "tp_adi" : "Tedavi Protokolü Adı",
        "tp_olustur" : "Tedavi Protokolü Oluştur",
        "tedavi_protokolleri" : "Tedavi Protokolleri",
        "tp_ayarlari" : "Tedavi Protokolü Ayarları",
        "yetkilisi_oldugu_kullanicilar" : "YETKİLİSİ OLDUĞU KULLANICILAR",
        "tedavi_ata":"Tedavi Ata",
        "recete_ara_plc":"Tedavi protokolü adına göre arama yapabilirsiniz",
        "tarih" : "Tarih",
        "kvkk_kabul" : "KVKK sözleşmesini kabul ediyorum",
        "uriner_gun_sayisi":"Üriner Günlük Sayısı",
        "gun" : "Gün",
        "tedavi_baslangic_tarihi" : "Tedavi Başlangıç Tarihi",
        "tedavi_protokolu_bilgileri" : "Tedavi Protokolü Bilgileri",
        "tedavi_protokolu_secilmedi" : "Tedavi protokolü seçilmedi",
        "ANKET" : "Anket",
        "URINER" : "Üriner",
        "TEDAVI" : "Tedavi",
        "tedavi_atanmak_uzere" : "Tedavi hastaya atanmak üzere, işleme devam etmek istiyor musunuz?",
        "atanma_tarihi" : "Atanma Tarihi",
        "durum" : "Durum",
        "BASLAMADI" : "Başlamadı",
        "DEVAM_EDIYOR" : "Devam Ediyor",
        "IPTAL_EDILDI" : "İptal Edildi",
        "TAMAMLANDI" : "Tamamlandı",
        "HESAPLANMADI" : "Hesaplanmadı",
        "KISMEN_IYILESME" : "Kısmen İyileşme",
        "TAMAMEN_IYILESME" : "Tamamen İyileşme",
        "BASARISIZ" : "Başarısız",
        "iptal_et" : "İptal Et",
        "tedavi_iptal_islemini_onayla" : "Tedavi iptal işlemini onaylıyor musunuz?",
        "tedavi_takvimi" : "Tedavi Takvimi",
        "tedavi_goruntule" : "Tedavi Görüntüle",
        "ref_tp_bilgileri" : "Referans Tedavi Protokolü Bilgileri",
        "hasta_adi" : "Hasta Adı",
        "tedavi_durumu" : "Tedavi Durumu",
        "manuel_aksiyon_ekle" : "Manuel Aksiyon Ekle",
        "aksiyon_tarihi" : "Aksiyon Tarihi",
        "aksiyon_ekle" : "Aksiyon Ekle",
        "anket_skoru":"Anket Skoru",
        "baslangic_tarihi":"Başlangıç Tarihi",
        "bitis_tarihi":"Bitiş Tarihi",
        "hata_kodu": "Hata Kodu",
        "tedavi_frekansi_hz": "Tedavi Frekansı [Hz]",
        "tedavi_dbg_us": "Tedavi Darbe Genişliği [us]",
        "ayarlanan_tedavi_akimi_ma": "Ayarlanan Tedavi Akımı [mA]",
        "olculen_tedavi_akimi_ma": "Ölçülen Tedavi Akımı [mA]",
        "ortalama_ted_gerilimi_peak_v": "Ortalama  Tedavi Gerilimi (Peak) [V]",
        "ortalama_tedavi_empedansi": "Ortalama Tedavi Empedansı (Direnç) [ohm]",
        "min_tedavi_gerilimi": "Min. Tedavi Gerilimi (Peak) [V]",
        "max_tedavi_gerilimi": "Max. Tedavi Gerilimi (Peak) [V]",
        "hareketlilik_katsayisi": "Hareketlilik Katsayısı",
        "tedavi_sonrasi_pil_gerilimi": "Tedavi Sonu Pil Gerilimi [V]",
        "su_cihaz_id": "SmartUric Cihaz ID",
        "su_fw_versiyon": "SmartUric Firmware Versiyonu",
        "su_mobil_app_versiyon": "SmartUric Mobil Uygulama Versiyonu",
        "tamamlandi":"Tamamlandı",
        "planlanan_tarih":"Planlanan Tarih",
        "uriner_gunluk_goruntule" : "Üriner Günlük Görüntüle",
        "ICME" : "İçme",
        "ISEME" : "İşeme",
        "KACIRMA" : "Kaçırma",
        "SIKISMA" : "Sıkışma",
        "HAREKET_ILE" : "Hareket ile",
        "YETISEMEME" : "Yetişememe",
        "FARK_ETMEDEN" : "Fark Etmeden",
        "SU" : "Su",
        "CAY" : "Çay",
        "KAHVE" : "Kahve",
        "GAZLI_ICECEK" : "Gazlı İçecek",
        "CORBA" : "Çorba",
        "DIGER" : "Diğer",
        "SU_BARDAGI" : "Su Bardağı",
        "CAY_BARDAGI" : "Çay Bardağı",
        "KAHVE_FINCANI" : "Kahve Fincanı",
        "KUPA" : "Kupa",
        "CORBA_KASESI" : "Çorba Kasesi",
        "SISE" : "Şişe",
        "KUCUK" : "Küçük",
        "ORTA" : "Orta",
        "BUYUK" : "Büyük",
        "kacirma_turu" : "Kaçırma Türü",
        "icecek" : "İçecek",
        "olcum_kabi" : "Olçum Kabı",
        "boyut" : "Boyut",
        "adet" : "Adet",
        "miktar" : "Miktar",
        "birim" : "Birim",
        "anket_goruntule" : "Anket Görüntüle",
        "anket_henuz_yanitlanmamis" : "Anket henüz yanıtlanmamış.",
        "anket" : "Anket",
        "soru"  : "Soru",
        "cevap" : "Cevap",
        "soru1" : "Gündüz sık idrara çıkma",
        "soru2" : "Rahatsız edici bir idrar sıkışması",
        "soru3" : "Ani, beklenmedik bir idrar sıkışması",
        "soru4" : "Kazara az miktarda idrar kaçırma",
        "soru5" : "Gece idrara gitme",
        "soru6" : "Gece idrar yapma ihtiyacı ile uyanma",
        "soru7" : "Kontrol edilemez bir idrar sıkışması",
        "soru8" : "Aşırı idrar yapma isteği ile birlikte idrar kaçırma",
        "soru9" : "Ne sıklıkla idrar kaçırıyorsunuz?",
        "soru10" : "Genellikle ne kadar idrar kaçırırsınız (koruyucu taksanız da takmasanız da)?",
        "soru11" : "Genel olarak, idrar kaçırma günlük hayatınızı ne kadar etkiliyor?",
        "skor" : "Skor",

        "tedavi_surdurme_basarisi" : "TEDAVİ SÜRDÜRME BAŞARISI",
        "tedavi_basarisi" : "TEDAVİ BAŞARISI",
        "toplam_gecikme" : "TOPLAM GECİKME",
        "tedavi_durumu2" : "TEDAVİ DURUMU",
        "uygulanma_tarihi" : "Uygulanma Tarihi",
        "REF" : "Referans",
        "anket_basarisi" : "Anket Başarısı",
        "tedavi_surdurme_basarisi2" : "Tedavi Sürdürme Başarısı"
    }
}