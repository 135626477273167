import React from 'react'
import { api, server, prefix, endpoints } from '../../Main/Api'
import { useEffect } from 'react'

import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
const MySwal = withReactContent(Swal)

export default function Goruntule() {
  
  const { user,token }  = useContext(MainContext)
  const { t }           = useTranslation()
  const navigate        = useNavigate()
  const { id }          = useParams();

  const [contentLoading,setContentLoading]  = useState(false)
  const [recete,setRecete]                  = useState(false)
  const [ayarlar,setAyarlar]                = useState({})

  useEffect(() => {
    setContentLoading(true);
    
    let url = server + prefix + endpoints.recete_bilgi.replace(":id",id);
    api.request(url,'GET',null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {

      const { hata,mesaj } = response.data;

      if(hata){
        navigate("/tedavi-protokolu/listele")
      }else{
        const { data } = response.data;
        setAyarlar(data.ayarlar);
        setRecete(data.recete);

        setContentLoading(false);
      }
    });

  },[ ])

  return (
    <Layout baslik={ t('tp_olustur') }>

      <Link to={ "/tedavi-protokolu/duzenle/" + id }>
        <button className="btn btn-dark">
          <i className="fas fa-edit"></i> { t('duzenle') }
        </button>
      </Link>
    
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card shadow">

            {
              contentLoading ?
              <>
               <div className="text-center mt-5">
                    <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                    <p>{ t("yukleniyor...") }</p>
                </div>
              </>
              :
              <>
              <div className="card-body">

                <div className="row">
                  <div className="col-md-6">

                    <div className="form-group">
                      <label htmlFor="recete_adi">{ t('tp_adi') }</label>
                      <input id="recete_adi" value={ recete.recete_adi } type="text" className="form-control" disabled/>
                    </div>

                    {
                      user.rol == "ADMIN" &&
                      <>
                      <div className="form-group">
                        <label htmlFor="genel">{ t('genel_recete') }</label>
                        <select defaultValue={ recete.genel ? "1" : "0" } id="genel" className="form-control" disabled>
                          <option value="">{ t('secim_yapilmadi') }</option>
                          <option value="1">{ t('EVET') }</option>
                          <option value="0">{ t('HAYIR') }</option>
                        </select>
                      </div>
                      </>
                    }

                    <div className="form-group">
                      <label htmlFor="ayak">{ t('ayak') }</label>
                      <select defaultValue={ recete.uygulanacak_ayak } id="ayak" className="form-control" disabled>
                        <option value="">{ t('secim_yapilmadi') }</option>
                        <option value="SOL">{ t('SOL') }</option>
                        <option value="SAG">{ t('SAG') }</option>
                        <option value="CIFT">{ t('CIFT') }</option>
                      </select>
                    </div>

                  </div>

                  <div className="col-md-6">

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="hafta_sayisi" style={{ flex : 1 }}>{ t('hafta_sayisi') }</label>
                        <label htmlFor="hafta_sayisi">{ recete.hafta_sayisi }</label>
                      </div>
                      <input id="hafta_sayisi" type="range" className="custom-range" value={ recete.hafta_sayisi } min={ ayarlar.en_az_hafta_sayisi } max={ ayarlar.en_fazla_hafta_sayisi } step="1"/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="haftadaki_siklik" style={{ flex : 1 }}>{ t('haftadaki_siklik') }</label>
                        <label htmlFor="haftadaki_siklik">{ recete.siklik }</label>
                      </div>
                      <input id="haftadaki_siklik" type="range" className="custom-range" value={ recete.siklik } min={ ayarlar.en_az_haftadaki_siklik } max={ ayarlar.en_fazla_haftadaki_siklik } step="1"/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="tedavi_suresi" style={{ flex : 1 }}>{ t('tedavi_suresi_dk') }</label>
                        <label htmlFor="tedavi_suresi">{ recete.tedavi_suresi }</label>
                      </div>
                      <input id="tedavi_suresi" type="range" className="custom-range" value={ recete.tedavi_suresi } min={ ayarlar.en_az_tedavi_suresi } max={ ayarlar.en_fazla_tedavi_suresi } step="1" />
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="frekans" style={{ flex : 1 }}>{ t('frekans_hz') }</label>
                        <label htmlFor="frekans">{ recete.frekans }</label>
                      </div>
                      <input id="frekans" type="range" className="custom-range" value={ recete.frekans } min={ ayarlar.en_az_frekans } max={ ayarlar.en_fazla_frekans } step="1"/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="darbe_genisligi" style={{ flex : 1 }}>{ t('dbg_mikro_sn') }</label>
                        <label htmlFor="darbe_genisligi">{ recete.darbe_genisligi }</label>
                      </div>
                      <input id="darbe_genisligi" type="range" className="custom-range" value={ recete.darbe_genisligi } min={ ayarlar.en_az_darbe_genisligi } max={ ayarlar.en_fazla_darbe_genisligi } step={ 20 }/>
                    </div>

                  </div>
                  
                </div>                

              </div>
              </>
            }

          </div>
        </div>
      </div>

    </Layout>
  )
}
