import React from 'react'

import LeftSidebar from './LeftSidebar'
import Navbar from './Navbar'

export default function Layout(props) {
  return (
    <div style={ style }>
        <LeftSidebar/>
        <div style={ container }>
          <Navbar baslik={ props.baslik }/>
          <div style={ mainContent }>
            { props.children }
          </div>
        </div>
        
    </div>
  )
}

const style = {
  display : 'flex',
  flexDirection : "row",
  flex : 1,
  minHeight : '100vh',
}

const container = {
  width : '100%',
  overflowY : 'hidden'
}

const mainContent = {
  padding : 12
}