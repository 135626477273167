import React, { useEffect,useState } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import Layout from '../../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useNavigation, useParams, useSearchParams } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import HastaBilgileri from './HastaBilgileri'
import TedaviBilgileri from './TedaviBilgileri'

const MySwal = withReactContent(Swal)

export default function Sayfa(props) {
  const { user,token }  = useContext(MainContext)
  const { kullanici_kodu : param_kk }       = useParams()
  const [searchParams]                      = useSearchParams();

  const navigate                            = useNavigate()
  const { t }                               = useTranslation();

  const [kullaniciX,setKullaniciX]                  = useState()
  const [hastaBilgileri,setHastaBilgileri]          = useState()

  const [loading,setLoading]                        = useState(false)
  const [contentLoading, setContentLoading]         = useState(false)
  const [arsivIslem,setArsivIslem]                  = useState()
  const [arsivLoading,setArsivLoading]              = useState(false)

  const [tab,setTab]                                = useState("tab1")

  const arsivden_cikar = (kk) => {

    setArsivIslem(kk);
    setArsivLoading(true);

    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setArsivLoading(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_arsivden_cikar.replace(":id",kk);
        api.request(url,"POST",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          if(!hata){
            const kullaniciY = { ...kullaniciX };
            kullaniciY.arsive_alindi = false;
            setKullaniciX(kullaniciY);
          }

        });

      }
    })
  }

  useEffect(() => {
    
    if(searchParams.get("tab") != null){
      setTab(searchParams.get("tab"))
    }
    
    setContentLoading(true);
    let url = server + prefix + endpoints.hasta_bilgi.replace(":id",param_kk);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      
      const { data : responseData } = response;
      const { hata, mesaj } = responseData;

      if(hata){
        MySwal.fire({
          allowOutsideClick : false,
          allowEscapeKey : false,
          titleText : mesaj,
          confirmButtonText : t('tamam')
        }).then((x) => {
          navigate("/dashboard")
      })

      }else{

        setContentLoading(false);
        const { data } = responseData;

        setKullaniciX(data.kullanici);
        setHastaBilgileri(data.hasta);
      }

    }).catch((err) => {
      setContentLoading(false);
    })

  },[ param_kk ])

  const tabSec = (e) => {
    const tabX = e.target.getAttribute("data-target").replace("#","");

    navigate("?tab=" + tabX);

    setTab(tabX);
  }

  return (
    <Layout baslik={ t('hasta_goruntule') }>
        <div className="row">
          <div className="col-md-12">

            {
              (kullaniciX != undefined && kullaniciX.arsive_alindi) ?
              <>
              <div className="alert alert-danger">
                <p>{ t('arsive_alinan_kullanici') }</p>
                <p>{ t('arsive_alinma_tarihi') } : { kullaniciX.arsive_alinma_tarihi }</p>

                <button onClick={() => arsivden_cikar(kullaniciX.kullanici_kodu)} className="btn btn-outline-dark">
                  {
                    (arsivLoading && arsivIslem == kullaniciX.kullanici_kodu) ?
                    <>
                      <i className="fas fa-spinner fa-spin"></i>
                    </>
                    :
                    <>
                      <i className="fas fa-reply-all"></i> { t('arsivden_cikar') }
                    </>
                  }
                </button>
              </div>
              </>
              :
              <></>
            }

            {
              kullaniciX != null &&
              <>
              <div className="card shadow">
                <div className="card-body">

                  <div className="row">
                    <div className="col-md-4">

                      <div className="form-group">
                        <label htmlFor="ad_soyad">{ t('ad_soyad') }</label>
                        <input id="ad_soyad" value={ kullaniciX.ad + " " + kullaniciX.soyad } type="text" className="form-control" disabled/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="kullanici_kodu">{ t('kullanici_kodu') }</label>
                        <input id="kullanici_kodu" value={ kullaniciX.kullanici_kodu } type="text" className="form-control" disabled/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="cinsiyet">{ t('cinsiyet') }</label>
                        <input id="cinsiyet" value={ t(kullaniciX.cinsiyet) } type="text" className="form-control" disabled/>
                      </div>

                    </div>

                    <div className="col-md-4">

                      <div className="form-group">
                        <label htmlFor="dogum_tarihi">{ t('dogum_tarihi') }</label>
                        <input id="dogum_tarihi" value={ kullaniciX.dogum_tarihi } type="date" className="form-control" disabled/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="ulke">{ t('ulke') }</label>
                        <input id="ulke" value={ kullaniciX.ulke } type="text" className="form-control" disabled/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="sehir">{ t('sehir') }</label>
                        <input id="sehir" value={ kullaniciX.sehir } type="text" className="form-control" disabled/>
                      </div>                      
                      
                    </div>

                    <div className="col-md-4">

                      <div className="form-group">
                        <label htmlFor="email">{ t('email') }</label>
                        <input id="email" value={ kullaniciX.email } type="text" className="form-control" disabled/>
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="telefon">{ t('telefon') }</label>
                        <input id="telefon" value={ kullaniciX.telefon_kodu  + kullaniciX.telefon } type="text" className="form-control" disabled/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="cihaz_seri_no">{ t('cihaz_seri_no') }</label>
                        <input id="cihaz_seri_no" value={ hastaBilgileri.cihaz_seri_no } type="text" className="form-control" disabled/>
                      </div>

                    </div>
                  </div>

                  <div className="text-right">
                    <Link style={{ marginRight:8 }} to={ "/hasta/duzenle/" + kullaniciX.kullanici_kodu }>
                      <button className="btn btn-dark">
                        <i className="fas fa-edit"></i> { t('duzenle') }
                      </button>
                    </Link>
                  </div>

                </div>
              </div>
              </>
            }

            <div className="card shadow mt-2">
                {
                    contentLoading ?
                    <>
                        <div className="text-center mt-5">
                        <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                        <p>{ t("yukleniyor...") }</p>
                        </div>
                    </>
                    :
                    <>
                      <div className="card-body">

                        <nav style={ { fontSize: 20 } }>
                          <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button onClick={ tabSec } className={ tab == "tab1" ? "nav-link active text-danger" : "nav-link" } data-toggle="tab" data-target="#tab1" type="button" role="tab">{ t("hasta_bilgileri") }</button>
                            <button onClick={ tabSec } className={ tab == "tab2" ? "nav-link active text-danger" : "nav-link" } data-toggle="tab" data-target="#tab2" type="button" role="tab" >{ t("tedavi_bilgileri") }</button>
                          </div>
                        </nav>

                        <div className="tab-content p-3" id="nav-tabContent">

                          <div className={ tab == "tab1" ? "tab-pane fade show active" : "tab-pane fade" } id="tab1">
                            <HastaBilgileri bilgiler={ hastaBilgileri }/>
                          </div>

                          <div className={ tab == "tab2" ? "tab-pane fade show active" : "tab-pane fade" } id="tab2">
                            <TedaviBilgileri hasta={ kullaniciX }/>
                          </div>

                        </div>
                      </div>
                    </>
                }
            </div>

          </div>
        </div>
    </Layout>
  )
}
