import React from 'react'

import { MainContext,useContext } from '../Main/MainContext'

import FLAG_TR from '../../src/assets/tr.svg'
import FLAG_EN from '../../src/assets/en.svg'

export default function Navbar(props) {

  const { sidebarState,setSidebarState,setDil } = useContext(MainContext)

  const toggleSidebar = () => {
    setSidebarState(!sidebarState)
  }

  const dilDegistir = (dil) => {
    setDil(dil)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
        <button onClick={() => toggleSidebar()} className="btn navbar-brand" href="#">
            <i className="fas fa-bars"></i>
        </button>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <a className="nav-link" href="#">{ props.baslik }</a>
                </li>
            </ul>
            
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('tr')} href="#">
                        <img src={ FLAG_TR } style={ { width : 32 } } />
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('en')} href="#">
                        <img src={ FLAG_EN } style={ { width : 32 } } />
                    </button>
                </li>
            </ul>
        </div>
    </nav>
  )
}
