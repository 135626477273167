import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import '../metinler/i18n'

import { api,server,prefix,endpoints } from '../Main/Api'
import { MainContext,useContext } from '../Main/MainContext'

import Logo from '../assets/logo.png'
import BG from '../assets/background-green.jpg'

import FLAG_TR from '../../src/assets/tr.svg'
import FLAG_EN from '../../src/assets/en.svg'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom';
const MySwal = withReactContent(Swal)
export default function Giris(){

    const { setLogin,setUser,setToken,setDil } = useContext(MainContext)

    const dilDegistir = (dil) => {
        setDil(dil)
    }

    const { t } = useTranslation();

    const [ kk,setKK ]                          = useState('')
    const [ parola,setParola ]                  = useState('')
    const [ sozlesmeKabul,setSozlesmeKabul ]    = useState(false)

    const enter_handler = (e) => {
        if(e.key == "Enter"){
            giris()
        }
    }

    const giris = () => {

        if(!sozlesmeKabul){
            MySwal.fire({
                allowOutsideClick : false,
                allowEscapeKey : false,
                titleText : t('sozlesme_kabul_edilmedi'),
                confirmButtonText:t('tamam')
            })
            return;
        }

        MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : t('isleniyor'),
            didOpen: () => {
                MySwal.showLoading();
            },
        })

        const url = server + prefix + endpoints.access_token;
        api.request(url,'POST',{
            kullanici_kodu : kk,
            parola
        }).then((response) => {
            
            let { data : responseData,status } = response;

            const { hata,mesaj } = responseData;

            if(hata){
                setTimeout(() => {
                    MySwal.fire({
                        allowOutsideClick : false,
                        allowEscapeKey : false,
                        titleText : mesaj,
                        confirmButtonText : t('tamam')
                    })
                }, 500);
            }else{

                const { data,access_token } = responseData;

                setTimeout(() => {
                    MySwal.close();
                    localStorage.setItem("user",JSON.stringify(data));
                    localStorage.setItem("access_token",access_token);
                    setUser(data)
                    setToken(access_token);
                    setLogin(true);
                }, 500);
            }
            
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                MySwal.close();
            }, 500);
        })
        
    }

    return (
        <div className="mainContainerStyle" style={ mainContainerStyle }>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-start" style={ { minHeight : '100vh' } }>
                    <div className="col-md-6">
                        <div className="card shadow">
                            <div className="card-body">

                                <h3>{ t('giris_yap') }</h3>
                                <hr />

                                <div className="form-group">
                                    <label htmlFor="kullanici_kodu">{ t("kullanici_kodu") }</label>
                                    <input onKeyUp={ enter_handler } value={ kk } onChange={(e) => setKK(e.target.value)} type="text" className="form-control" autoComplete='new-password'/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="parola">{ t("parola") }</label>
                                    <input onKeyUp={ enter_handler } value={ parola } onChange={(e) => setParola(e.target.value)} type="password" className="form-control" autoComplete='new-password'/>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-check">
                                            <input id="sozlesmeler" className="form-check-input" type="checkbox" onChange={(e) => setSozlesmeKabul(e.target.checked) } checked={ sozlesmeKabul }/>
                                            <label className="form-check-label" htmlFor="sozlesmeler">
                                                { t("kvkk_kabul") }
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <Link to="/parolami-unuttum">{ t("parolami_unuttum") }</Link>
                                    </div>
                                </div>

                                <div className="text-right mt-3">
                                    <button onClick={() => giris() } className="btn btn-dark">
                                    { t("giris_yap") }
                                    </button>
                                </div>

                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('tr')} href="#">
                                        <img src={ FLAG_TR } style={ { width : 32 } } />
                                    </button>
                                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('en')} href="#">
                                        <img src={ FLAG_EN } style={ { width : 32 } } />
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="text-center mt-3" style={ { fontSize : "1.4rem" } }>
                            <strong>Smart</strong>Uric
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mainContainerStyle = {
    backgroundImage : `url(${BG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height : '100vh'
};