import React, { useEffect, useState } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { api,server,prefix,endpoints } from '../../Main/Api'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Dashboard() {

  const { user,token }                      = useContext(MainContext)
  const { t }                               = useTranslation()

  const [tsEnAz,setTsEnAz]                  = useState(0);
  const [tsEnFazla,setTsEnFazla]            = useState(0);
  const [hsEnAz,setHsEnAz]                  = useState(0);
  const [hsEnFazla,setHsEnFazla]            = useState(0);
  const [hskEnAz,setHskEnAz]                = useState(0);
  const [hskEnFazla,setHskEnFazla]          = useState(0);
  const [dgEnAz,setDgEnAz]                  = useState(0);
  const [dgEnFazla,setDgEnFazla]            = useState(0);
  const [frEnAz,setFrEnAz]                  = useState(0);
  const [frEnFazla,setFrEnFazla]            = useState(0);

  const [contentLoading,setContentLoading]  = useState(false);
  const [loading,setLoading]                = useState(false);

  useEffect(() => {
    setContentLoading(true)
    const url = server + prefix + endpoints.recete_ayarlari
    api.request(url,"GET",null,{ "Authorization" : "Bearer " + token  }).then((response) => {
        const { data } = response.data;

        setTsEnAz(data.en_az_tedavi_suresi)
        setTsEnFazla(data.en_fazla_tedavi_suresi)

        setHsEnAz(data.en_az_hafta_sayisi)
        setHsEnFazla(data.en_fazla_hafta_sayisi)
        
        setHskEnAz(data.en_az_haftadaki_siklik)
        setHskEnFazla(data.en_fazla_haftadaki_siklik)

        setDgEnAz(data.en_az_darbe_genisligi)
        setDgEnFazla(data.en_fazla_darbe_genisligi)

        setFrEnAz(data.en_az_frekans)
        setFrEnFazla(data.en_fazla_frekans)

        setContentLoading(false)
    });
  },[ ])

  const guncelle = () => {
    if(loading) return;
    setLoading(true);

    const url = server + prefix + endpoints.recete_ayarlarini_guncelle;
    api.request(url,"PUT",{
        en_az_tedavi_suresi : tsEnAz,
        en_fazla_tedavi_suresi : tsEnFazla,
        en_az_hafta_sayisi : hsEnAz,
        en_fazla_hafta_sayisi : hsEnFazla,
        en_az_haftadaki_siklik : hskEnAz,
        en_fazla_haftadaki_siklik : hskEnFazla,
        en_az_darbe_genisligi : dgEnAz,
        en_fazla_darbe_genisligi : dgEnFazla,
        en_az_frekans : frEnAz,
        en_fazla_frekans : frEnFazla,
    },{
        "Authorization" : "Bearer " + token 
    }).then((response) => {
        const { hata,mesaj } = response.data;

        MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam')
        })

        setLoading(false);
    });

  }

  return (
    <Layout baslik={ t('tp_ayarlari') }>
    
        <div className="row">
            <div className="col-md-6">
                <div className="card shadow">
                    <div className="card-body">
                        
                        {
                            contentLoading ?
                            <>
                                <div className="text-center mt-5">
                                    <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                                    <p>{ t("yukleniyor...") }</p>
                                </div>
                            </>
                            :
                            <>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>{ t('kriter') }</th>
                                            <th>{ t('en_az') }</th>
                                            <th>{ t('en_fazla') }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{ t('tedavi_suresi_dk') }</td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ tsEnAz } onChange={(e) => setTsEnAz(e.target.value)}/>
                                            </td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ tsEnFazla } onChange={(e) => setTsEnFazla(e.target.value)}/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{ t('hafta_sayisi') }</td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ hsEnAz } onChange={(e) => setHsEnAz(e.target.value)}/>
                                            </td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ hsEnFazla } onChange={(e) => setHsEnFazla(e.target.value)}/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{ t('haftadaki_siklik') }</td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ hskEnAz } onChange={(e) => setHskEnAz(e.target.value)}/>
                                            </td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ hskEnFazla } onChange={(e) => setHskEnFazla(e.target.value)}/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{ t('darbe_genisligi') + " (" + t('mikro_sn') + ")" }</td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ dgEnAz } onChange={(e) => setDgEnAz(e.target.value)}/>
                                            </td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ dgEnFazla } onChange={(e) => setDgEnFazla(e.target.value)}/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{ t('frekans') + " " + "(Hz)" }</td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ frEnAz } onChange={(e) => setFrEnAz(e.target.value)}/>
                                            </td>
                                            <td style={{ width:150 }}>
                                                <input type="text" className="form-control text-center" value={ frEnFazla } onChange={(e) => setFrEnFazla(e.target.value)}/>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                            <button onClick={() => guncelle()} className="btn btn-dark btn-block">
                                {
                                    loading ?
                                    <>
                                        <i className="fas fa-spinner fa-spin"></i> { t('isleniyor') }
                                    </>
                                    :
                                    <>
                                        <i className="fas fa-check"></i> { t('guncelle') }
                                    </>
                                }                                
                            </button>
                            </>
                        }

                    </div>
                </div>
            </div>
        </div>

    </Layout>
  )
}
