import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import Layout from '../../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { api,server,prefix,endpoints } from '../../../Main/Api';
import { useParams } from 'react-router-dom';

export default function UrinerGoruntule() {

  const { user,token }        = useContext(MainContext)
  const { t }                 = useTranslation()

  const [loading,setLoading]  = useState(false)
  const [loglar,setLoglar]    = useState([])
  const { id }                = useParams()

  useEffect(() => {
    uriner_loglari();
  },[ ])

  const uriner_loglari = () => {
    setLoading(true)
    let url = server + prefix + endpoints.uriner_loglari.replace(":id",id);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data } = response;

      setLoglar(data.data)
      
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  const aksiyon = (aks) => {
    if(aks == "ICME"){
      return (
        <span className="badge badge-secondary">{ t(aks) }</span>
      )
    }

    if(aks == "ISEME"){
      return (
        <span className="badge badge-info">{ t(aks) }</span>
      )
    }

    if(aks == "KACIRMA"){
      return (
        <span className="badge badge-danger">{ t(aks) }</span>
      )
    }

    if(aks == "SIKISMA"){
      return (
        <span className="badge badge-warning">{ t(aks) }</span>
      )
    }
    
  }

  return (
    <Layout baslik={ t('uriner_gunluk_goruntule') }>
      {
        loading ? 
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>

          <div className="table-responsive">
            <table className="table table-striped">

              <thead>
                <tr>
                  <th className='text-center'>#</th>
                  <th className='text-center'>{ t("tarih") }</th>
                  <th className='text-center'>{ t("aksiyon") }</th>
                  <th className='text-center'>{ t("kacirma_turu") }</th>
                  <th className='text-center'>{ t("icecek") }</th>
                  <th className='text-center'>{ t("olcum_kabi") }</th>
                  <th className='text-center'>{ t("boyut") }</th>
                  <th className='text-center'>{ t("adet") }</th>
                  <th className='text-center'>{ t("miktar") }</th>
                  <th className='text-center'>{ t("birim") }</th>
                </tr>
              </thead>

              <tbody>
                {
                  loglar.map((item,index) => (
                    <tr>
                      <td className='text-center'>{ index + 1 }</td>
                      <td className='text-center'>{ item.tarih }</td>
                      <td className='text-center'> { aksiyon(item.aksiyon) } </td>
                      <td className='text-center'>{ t(item.kacirma_turu) }</td>
                      <td className='text-center'>{ t(item.icecek) }</td>
                      <td className='text-center'>{ t(item.olcum_kabi) }</td>
                      <td className='text-center'>{ t(item.boy) }</td>
                      <td className='text-center'>{ item.adet }</td>
                      <td className='text-center'>{ item.miktar }</td>
                      <td className='text-center'>{ item.metrik != null ? item.metrik.toUpperCase() : "" }</td>
                    </tr>
                  ))
                }
              </tbody>

            </table>
          </div>

        </>
      }
    </Layout>
  )
}
