import React, { useEffect, useState } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TedaviProtokolu from '../../Components/TedaviProtokolu'
const MySwal = withReactContent(Swal)

export default function Ata(){

    const { user,token }                            = useContext(MainContext)
    const { t }                                     = useTranslation()
    const { kullanici_kodu : kk }                   = useParams()
    const navigate                                  = useNavigate()


    const [ loading,setLoading ]                    = useState(false)
    const [ recete,setRecete ]                      = useState()
    const [ ayarlar,setAyarlar ]                    = useState()
    const [ takvim,setTakvim ]                      = useState([])
    const [ urinGun,setUriGun ]                     = useState(2)
    const [ baslangicTarihi,setBaslangicTarihi ]    = useState(new Date().toISOString().slice(0,10))
    const [ btnLoading,setBtnLoading ]              = useState(false)

    useEffect(() => {
        setLoading(true)
        const url = server + prefix + endpoints.recete_ayarlari
        api.request(url,"GET",null,{ "Authorization" : "Bearer " + token  }).then((response) => {
            const { data } = response.data;
    
            setAyarlar(data)
    
            setLoading(false)
        });
      },[ ])
    
    useEffect(() => {

        if(recete == null || urinGun == null || baslangicTarihi == null){
            return;
        }
        
        if(recete != null){
            recete_onizleme()
        }

    },[ recete,urinGun,baslangicTarihi ])

    const recete_onizleme = () => {
        setLoading(true)
        let url = server + prefix + endpoints.recete_onizleme.replace(":id",recete.id);
        api.request(url,"GET",{
            baslangic_tarihi : baslangicTarihi,
            uriner_gun : urinGun
        },{
          "Authorization" : "Bearer " + token
        }).then((response) => {
          const { data : responseData } = response;

          setTakvim(responseData.data);

          setLoading(false)
        }).catch((err) => {
          setLoading(true)
        })
    }

    const btnTedaviAta = () => {

        if(btnLoading) return;

        MySwal.fire({
            title : t('islemi_onayla'),
            html : "<i>" + t('tedavi_atanmak_uzere') + "</i>",
            icon : 'warning',
            confirmButtonText : t('evet'),
            denyButtonText : t('hayir'),
            showDenyButton : true
        }).then((x) => {
            if(x.isConfirmed){
                tedaviAta()
            }
        })
    }

    const tedaviAta = () => {
        setBtnLoading(true)

        const url = server + prefix + endpoints.tedavi_ata
        api.request(url,"POST",{
            kullanici_kodu : kk,
            recete_id : recete.id,
            baslangic_tarihi : baslangicTarihi,
            uriner_gun : urinGun
        },{ "Authorization" : "Bearer " + token  }).then((response) => {
            
            const { hata,mesaj } = response.data;

            MySwal.fire({
                title : mesaj,
                icon : hata ? 'warning' : 'success',
                confirmButtonText : t('tamam'),
            }).then((x) => {
                if(x.isConfirmed){
                    if(!hata){
                        navigate("/hasta/goruntule/" + kk + "?tab=tab2")
                    }
                }
            })
    
            setBtnLoading(false)
        });
    }

    return (
        <Layout baslik={ t('tedavi_ata') }>

            <div className="row">
                <div className="col-md-6 col-12">
                    <div className="card shadow">
                        <div className="card-body">
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <TedaviProtokolu recete={ recete } setRecete={ setRecete }/>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="uriner_gun_sanisi">{ t("uriner_gun_sayisi") }</label>
                                        <select defaultValue={ 2 } value={ urinGun } onChange={(e) => setUriGun(e.target.value)} name="uriner_gun_sanisi" id="uriner_gun_sanisi" className="form-control">
                                            <option value="2">2 { t('gun') }</option>
                                            <option value="3">3 { t('gun') }</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="baslangic_tarihi">{ t('tedavi_baslangic_tarihi') }</label>
                                        <input name="baslangic_tarihi" id="baslangic_tarihi" type="date" value={ baslangicTarihi } onChange={(e) => setBaslangicTarihi(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            {
                                loading ?
                                <>
                                    <div className="text-center mt-5">
                                    <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                                    <p>{ t("yukleniyor...") }</p>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        (takvim.length > 0) &&
                                        <>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t('tarih') }</th>
                                                            <th>{ t('aksiyon') }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            takvim.map((item,index) => {
                                                                return(
                                                                    <tr>
                                                                        <td>{ index + 1 }</td>
                                                                        <td>{ item.tarih }</td>
                                                                        <td>
                                                                            {
                                                                                (item.aksiyon == "TEDAVI") &&
                                                                                <>
                                                                                    <span className="badge badge-success">{ t(item.aksiyon) }</span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                (item.aksiyon == "URINER") &&
                                                                                <>
                                                                                    <span className="badge badge-danger">{ t(item.aksiyon) }</span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                (item.aksiyon == "ANKET") &&
                                                                                <>
                                                                                    <span className="badge badge-info">{ t(item.aksiyon) }</span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <button onClick={() => btnTedaviAta()} className="btn btn-danger btn-block">
                                                {
                                                    btnLoading ?
                                                    <>
                                                        <i className="fas fa-spinner fa-spin"></i> { t('isleniyor') }
                                                    </>
                                                    :
                                                    <>
                                                        <i className="fas fa-check"></i> { t('tedavi_ata') }
                                                    </>
                                                }
                                            </button>
                                        </>
                                    }
                                </>
                            }

                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div className="card shadow">
                        <div className="card-body">
                            <h4>{ t('tedavi_protokolu_bilgileri') }</h4>

                            {
                                recete == null ?
                                <>
                                    <p class="text-danger">{ t('tedavi_protokolu_secilmedi') }</p>
                                </>
                                :
                                <>
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="recete_adi">{ t('tp_adi') }</label>
                                            <input id="recete_adi" value={ recete.recete_adi } type="text" className="form-control" disabled/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="ayak">{ t('ayak') }</label>
                                            <select defaultValue={ recete.uygulanacak_ayak } id="ayak" className="form-control" disabled>
                                                <option value="">{ t('secim_yapilmadi') }</option>
                                                <option value="SOL">{ t('SOL') }</option>
                                                <option value="SAG">{ t('SAG') }</option>
                                                <option value="CIFT">{ t('CIFT') }</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                                <div style={ { display:'flex' } }>
                                                    <label htmlFor="hafta_sayisi" style={{ flex : 1 }}>{ t('hafta_sayisi') }</label>
                                                    <label htmlFor="hafta_sayisi">{ recete.hafta_sayisi }</label>
                                                </div>
                                                <input id="hafta_sayisi" type="range" className="custom-range" value={ recete.hafta_sayisi } min={ ayarlar.en_az_hafta_sayisi } max={ ayarlar.en_fazla_hafta_sayisi } step="1"/>
                                            </div>

                                            <div className="form-group">
                                                <div style={ { display:'flex' } }>
                                                    <label htmlFor="haftadaki_siklik" style={{ flex : 1 }}>{ t('haftadaki_siklik') }</label>
                                                    <label htmlFor="haftadaki_siklik">{ recete.siklik }</label>
                                                </div>
                                                <input id="haftadaki_siklik" type="range" className="custom-range" value={ recete.siklik } min={ ayarlar.en_az_haftadaki_siklik } max={ ayarlar.en_fazla_haftadaki_siklik } step="1"/>
                                            </div>

                                            <div className="form-group">
                                                <div style={ { display:'flex' } }>
                                                    <label htmlFor="tedavi_suresi" style={{ flex : 1 }}>{ t('tedavi_suresi_dk') }</label>
                                                    <label htmlFor="tedavi_suresi">{ recete.tedavi_suresi }</label>
                                                </div>
                                                <input id="tedavi_suresi" type="range" className="custom-range" value={ recete.tedavi_suresi } min={ ayarlar.en_az_tedavi_suresi } max={ ayarlar.en_fazla_tedavi_suresi } step="1" />
                                            </div>

                                            <div className="form-group">
                                                <div style={ { display:'flex' } }>
                                                    <label htmlFor="frekans" style={{ flex : 1 }}>{ t('frekans_hz') }</label>
                                                    <label htmlFor="frekans">{ recete.frekans }</label>
                                                </div>
                                                <input id="frekans" type="range" className="custom-range" value={ recete.frekans } min={ ayarlar.en_az_frekans } max={ ayarlar.en_fazla_frekans } step="1"/>
                                            </div>

                                            <div className="form-group">
                                                <div style={ { display:'flex' } }>
                                                    <label htmlFor="darbe_genisligi" style={{ flex : 1 }}>{ t('dbg_mikro_sn') }</label>
                                                    <label htmlFor="darbe_genisligi">{ recete.darbe_genisligi }</label>
                                                </div>
                                                <input id="darbe_genisligi" type="range" className="custom-range" value={ recete.darbe_genisligi } min={ ayarlar.en_az_darbe_genisligi } max={ ayarlar.en_fazla_darbe_genisligi } step={ 20 }/>
                                            </div>

                                    </div>

                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}