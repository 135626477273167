import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate, useNavigation } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Listele() {

  const { user,token }  = useContext(MainContext)
  const { t }           = useTranslation()

  const [sayfa,setSayfa]                        = useState(1)
  const [sonSayfa,setSonSayfa]                  = useState(1)
  const [ara,setAra]                            = useState()
  const [loading,setLoading]                    = useState(false)

  const [receteler,setReceteler]                = useState([])

  const [silinenTP,setSilinenTP]                = useState(0)
  const [siliniyor,setSiliniyor]                = useState(false)

  const listele = () => {
    setLoading(true)
    const url = server + prefix + endpoints.recete_listele;
    api.request(url,"GET",{
      ara,
      page : sayfa,
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data : responseData } = response;

      const { sayfa,son_sayfa } = responseData;
      
      setSonSayfa(son_sayfa);
      setSayfa(sayfa)

      setReceteler(responseData.data);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    listele()
  },[sayfa])

  const ileri = () => {
    if(sayfa >= sonSayfa) return;
    
    const currentPage = sayfa;
    setSayfa(currentPage + 1)
  }

  const geri = () => {
    if(sayfa < 2) return;

    const currentPage = sayfa
    setSayfa(currentPage - 1);
  }

  const sil = (id) => {
    setSilinenTP(id);
    setSiliniyor(true);
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('silme_islemini_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setSiliniyor(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.recete_sil.replace(":id",id);
        api.request(url,"DELETE",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          let recetelerX = [ ...receteler ];
          recetelerX = receteler.filter(x => x.id != id);
          setReceteler(recetelerX);

        });

      }
    })
  }

  return (
    <Layout baslik={ t('tedavi_protokolleri') }>

      <Link to="/tedavi-protokolu/olustur">
        <button className="btn btn-dark">
          <i className="fas fa-plus"></i> { t('yeni_olustur') }
        </button>
      </Link>

      {
        loading ?
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>
        <div className="table-responsive mt-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>{ t('tp_adi') }</th>
                <th className='text-center'>{ t('genel_recete') }</th>
                <th className='text-center'>{ t('olusturulma_tarihi') }</th>
                <th className='text-center'>{ t('goruntule') }</th>
                <th className='text-center'>{ t('sil') }</th>
              </tr>
            </thead>
            <tbody>
              {
                receteler.map((item,index) => (
                  <tr>
                    <td className='text-center'>{ index + 1 }</td>
                    <td className='text-center'>{ item.recete_adi }</td>
                    <td className='text-center'>{ item.genel ? t("EVET") : t("HAYIR") }</td>
                    <td className='text-center'>{ item.olusturulma_tarihi }</td>
                    <td className='text-center'>
                      <Link to={ "/tedavi-protokolu/goruntule/" + item.id }>
                        <button className="btn btn-success">
                          <i className="fas fa-eye"></i> { t("goruntule") }
                        </button>
                      </Link>
                    </td>
                    <td class="text-center">
                      <button onClick={() => sil(item.id)} className="btn btn-danger">
                        {
                          (siliniyor && silinenTP == item.id) ?
                          <>
                            <i className="fas fa-spinner fa-spin"></i>
                          </>
                          :
                          <>
                            <i className="fas fa-trash-alt"></i> { t('sil') }
                          </>
                        }
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="text-right">
            <button onClick={() => geri()} className="btn btn-dark mr-1" disabled={ sayfa < 2 ? true : false }>
              <i className="fas fa-arrow-left"></i>
            </button>
            <span>{ sayfa + "/" + sonSayfa }</span>
            <button onClick={() => ileri()} className="btn btn-dark ml-2" disabled={ sayfa >= sonSayfa ? true : false }>
              <i className="fas fa-arrow-right"></i>
            </button>
        </div>
        </>
      }
    
    </Layout>
  )
}
