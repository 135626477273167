import * as React from "react";
import { BrowserRouter,Routes,Route,Navigate  } from "react-router-dom";

import Giris from "./sayfalar/Giris";
import ParolamiUnuttum from "./sayfalar/ParolamiUnuttum";
import Dashboard from "./sayfalar/Dashboard";

import KullaniciListele from "./sayfalar/Kullanici/Listele";
import KullaniciOlustur from "./sayfalar/Kullanici/Olustur";
import KullaniciGoruntule from "./sayfalar/Kullanici/Goruntule/Sayfa";
import KullaniciDuzenle from "./sayfalar/Kullanici/Duzenle";

import HastaListele from "./sayfalar/Hasta/Listele";
import HastaOlustur from "./sayfalar/Hasta/Olustur";
import HastaGoruntule from "./sayfalar/Hasta/Goruntule/Sayfa";
import HastaDuzenle from "./sayfalar/Hasta/Duzenle";

import TPOlustur from "./sayfalar/TedaviProtokolu/Olustur";
import TPGoruntule from "./sayfalar/TedaviProtokolu/Goruntule";
import TPDuzenle from "./sayfalar/TedaviProtokolu/Duzenle";
import TPListele from "./sayfalar/TedaviProtokolu/Listele";
import TPAyarlari from "./sayfalar/TedaviProtokolu/Ayarlar";

import TedaviAta from "./sayfalar/Tedavi/Ata";
import TedaviGoruntule from "./sayfalar/Hasta/Goruntule/TedaviGoruntule";

import UrinerGoruntule from "./sayfalar/Hasta/Goruntule/UrinerGoruntule";
import AnketGoruntule from "./sayfalar/Hasta/Goruntule/AnketGoruntule";

import { MainContext,useContext } from './Main/MainContext';

export default function Navigator(){

  const { login } = useContext(MainContext);

  return (
    <BrowserRouter>
      <Routes>
        {
          login ?
          <>
            <Route path="/" element={<Navigate to="/dashboard" />}/>
            <Route path="/dashboard" Component={ Dashboard }/>
            <Route path="/kullanici/listele" Component={ KullaniciListele }/>
            <Route path="/kullanici/olustur" Component={ KullaniciOlustur }/>
            <Route path="/kullanici/goruntule/:kullanici_kodu" Component={ KullaniciGoruntule }/>
            <Route path="/kullanici/duzenle/:kullanici_kodu" Component={ KullaniciDuzenle }/>

            <Route path="/hasta/listele" Component={ HastaListele }/>
            <Route path="/hasta/olustur" Component={ HastaOlustur }/>
            <Route path="/hasta/goruntule/:kullanici_kodu" Component={ HastaGoruntule }/>
            <Route path="/hasta/duzenle/:kullanici_kodu" Component={ HastaDuzenle }/>

            <Route path="/tedavi-protokolu/olustur" Component={ TPOlustur }/>
            <Route path="/tedavi-protokolu/goruntule/:id" Component={ TPGoruntule }/>
            <Route path="/tedavi-protokolu/listele" Component={ TPListele }/>
            <Route path="/tedavi-protokolu/ayarlari" Component={ TPAyarlari }/>
            <Route path="/tedavi-protokolu/duzenle/:id" Component={ TPDuzenle }/>

            <Route path="/:kullanici_kodu/tedavi-ata" Component={ TedaviAta }/>
            <Route path="/tedavi/goruntule/:id" Component={ TedaviGoruntule }/>
            <Route path="/uriner/goruntule/:id" Component={ UrinerGoruntule }/>
            <Route path="/anket/goruntule/:id" Component={ AnketGoruntule }/>
          </>
          :
          <>
          <Route path="/" Component={ Giris }/>
          <Route path="/parolami-unuttum" Component={ ParolamiUnuttum }/>
          </>
        }
        
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}
