import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import Layout from '../../../Components/Layout'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate, useNavigation } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function TedaviBilgileri(props) {

  const { user,token }  = useContext(MainContext)
  const { t }           = useTranslation()
  const { hasta }       = props

  const [sayfa,setSayfa]                        = useState(1)
  const [sonSayfa,setSonSayfa]                  = useState(1)
  const [loading,setLoading]                    = useState(false)

  const [tedaviler,setTedaviler]                = useState([])

  const [silinenTedavi,setSilinenTedavi]        = useState(0)
  const [siliniyor,setSiliniyor]                = useState(false)

  const listele = () => {
    setLoading(true)
    const url = server + prefix + endpoints.tedavi_listele;
    api.request(url,"GET",{
      kullanici_kodu : hasta != null ? hasta.kullanici_kodu : 0,
      page : sayfa,
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data : responseData } = response;

      const { sayfa,son_sayfa } = responseData;
      
      setSonSayfa(son_sayfa);
      setSayfa(sayfa)
      setTedaviler(responseData.data)

      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    listele()
  },[sayfa])

  const ileri = () => {
    if(sayfa >= sonSayfa) return;
    
    const currentPage = sayfa;
    setSayfa(currentPage + 1)
  }

  const geri = () => {
    if(sayfa < 2) return;

    const currentPage = sayfa
    setSayfa(currentPage - 1);
  }

  const sil = (id) => {
    setSilinenTedavi(id);
    setSiliniyor(true);
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('tedavi_iptal_islemini_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setSiliniyor(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.tedavi_iptal.replace(":id",id);
        api.request(url,"POST",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          let tedavilerX = [ ...tedaviler ];
          tedavilerX.find(x => x.id == id).durum = "IPTAL_EDILDI";
          setTedaviler(tedavilerX);

        });

      }
    })
  }

  return (
    <div>
      {
        loading ?
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>
        <div className="table-responsive mt-3">

          {
            hasta != null &&
            <>
            <div className="text-right mb-3">
              <Link to={ "/" + hasta.kullanici_kodu + "/tedavi-ata" }>
                <button className="btn btn-warning">
                  <i className="fas fa-notes-medical"></i> { t('tedavi_ata') }
                </button>
              </Link>
            </div>
            </>
          }

          <table className="table table-striped">
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>{ t('tp_adi') }</th>
                <th className='text-center'>{ t('atanma_tarihi') }</th>
                <th className='text-center'>{ t('durum') }</th>
                <th className='text-center'>{ t('goruntule') }</th>
                <th className='text-center'>{ t('iptal_et') }</th>
              </tr>
            </thead>
            <tbody>
              {
                tedaviler.map((item,index) => (
                  <tr>
                    <td className='text-center'>{ index + 1 }</td>
                    <td className='text-center'>{ item.referans_recete_adi }</td>
                    <td className='text-center'>{ item.tedavi_atama_tarihi }</td>
                    <td className='text-center'>{ t(item.durum) }</td>
                    <td className='text-center'>
                      <Link to={ "/tedavi/goruntule/" + item.id }>
                        <button className="btn btn-success">
                          <i className="fas fa-eye"></i> { t("goruntule") }
                        </button>
                      </Link>
                    </td>
                    <td class="text-center">
                      {
                        (item.durum != "IPTAL_EDILDI" && item.durum != "TAMAMLANDI") &&
                        <>
                          <button onClick={() => sil(item.id)} className="btn btn-danger">
                            {
                              (siliniyor && silinenTedavi == item.id) ?
                              <>
                                <i className="fas fa-spinner fa-spin"></i>
                              </>
                              :
                              <>
                                <i className="fas fa-times"></i> { t('iptal_et') }
                              </>
                            }
                          </button>
                        </>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="text-right">
            <button onClick={() => geri()} className="btn btn-dark mr-1" disabled={ sayfa < 2 ? true : false }>
              <i className="fas fa-arrow-left"></i>
            </button>
            <span>{ sayfa + "/" + sonSayfa }</span>
            <button onClick={() => ileri()} className="btn btn-dark ml-2" disabled={ sayfa >= sonSayfa ? true : false }>
              <i className="fas fa-arrow-right"></i>
            </button>
        </div>
        </>
      }
    </div>
  )
}
