import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function Bilgiler(props) {
  const { bilgiler }  = props;
  const { t }         = useTranslation();

  useEffect(() => {
    console.log(bilgiler)
  },[ ])

  if(bilgiler == null){
    return (
      <></>
    )
  }

  return (
    <div className="row">
      <div className="col-md-6">
      <div className="form-group">
          <label htmlFor="boy">{ t('boy') }</label>
          <input disabled id="boy" value={ bilgiler.boy } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="kilo">{ t('kilo') }</label>
          <input disabled id="kilo" value={ bilgiler.kilo } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="menapoz_sure">{ t('menapoz_sure') }</label>
          <input disabled id="menapoz_sure" value={ bilgiler.menapoz_sure } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="q_tipi_testi">{ t('q_tipi_testi') }</label>
          <input disabled id="q_tipi_testi" value={ bilgiler.q_tipi_testi } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="pad_testi">{ t('pad_testi') }</label>
          <input disabled id="pad_testi" value={ bilgiler.pad_testi } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="tv_usg">{ t('tv_usg') }</label>
          <input disabled id="tv_usg" value={ bilgiler.tv_usg } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="bmi">{ t('bmi') }</label>
          <input disabled id="bmi" value={ bilgiler.bmi } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="residu_idrar_volumu">{ t('residu_idrar_volumu') }</label>
          <input disabled id="residu_idrar_volumu" value={ bilgiler.residu_idrar_volumu } type="text" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="sikayeti">{ t('sikayeti') }</label>
          <textarea disabled id="sikayeti" value={ bilgiler.sikayeti == null ? '' : bilgiler.sikayeti } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="idrar_inkontinans_oykusu">{ t('idrar_inkontinans_oykusu') }</label>
          <textarea disabled id="idrar_inkontinans_oykusu" value={ bilgiler.idrar_inkontinans_oykusu == null ? '' : bilgiler.idrar_inkontinans_oykusu } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="dogum_sayisi_ve_sekli">{ t('dogum_sayisi_ve_sekli') }</label>
          <textarea disabled id="dogum_sayisi_ve_sekli" value={ bilgiler.dogum_sayisi_ve_sekli == null ? '' : bilgiler.dogum_sayisi_ve_sekli } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="gecirdigi_operasyonlar">{ t('gecirdigi_operasyonlar') }</label>
          <textarea disabled id="gecirdigi_operasyonlar" value={ bilgiler.gecirdigi_operasyonlar == null ? '' : bilgiler.gecirdigi_operasyonlar } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="kullandigi_ilaclar">{ t('kullandigi_ilaclar') }</label>
          <textarea disabled id="kullandigi_ilaclar" value={ bilgiler.kullandigi_ilaclar == null ? '' : bilgiler.kullandigi_ilaclar } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="iri_dogum_oykusu">{ t('iri_dogum_oykusu') }</label>
          <textarea disabled id="iri_dogum_oykusu" value={ bilgiler.iri_dogum_oykusu == null ? '' : bilgiler.iri_dogum_oykusu } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="urogram">{ t('urogram') }</label>
          <textarea disabled id="urogram" value={ bilgiler.urogram == null ? '' : bilgiler.urogram } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="urodinamik_tetkik">{ t('urodinamik_tetkik') }</label>
          <textarea disabled id="urodinamik_tetkik" value={ bilgiler.urodinamik_tetkik == null ? '' : bilgiler.urodinamik_tetkik } type="text" className="form-control"></textarea>
      </div>

      <div className="form-group">
          <label htmlFor="sigara_bilgi">{ t('sigara_bilgi') }</label>
          <textarea disabled id="sigara_bilgi" value={ bilgiler.sigara_bilgi == null ? '' : bilgiler.sigara_bilgi } type="text" className="form-control"></textarea>
      </div>

      </div>

      <div className="col-md-6">

      <div className="form-group">
          <label htmlFor="son_adet_tarihi">{ t('son_adet_tarihi') }</label>
          <input disabled id="son_adet_tarihi" value={ bilgiler.son_adet_tarihi } type="date" className="form-control" />
      </div>

      <div className="form-group">
          <label htmlFor="medeni_hal">{ t('medeni_hal') }</label>
          <select disabled id="medeni_hal" defaultValue={ bilgiler.medeni_hal } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="BEKAR">{ t("BEKAR") }</option>
          <option value="EVLI">{ t("EVLI") }</option>
          <option value="DUL">{ t("DUL") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="meslek">{ t('meslek') }</label>
          <select disabled id="meslek" defaultValue={ bilgiler.meslek } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="CALISIYOR">{ t("CALISIYOR") }</option>
          <option value="CALISMIYOR">{ t("CALISMIYOR") }</option>
          <option value="EMEKLI">{ t("EMEKLI") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="vajinal_dogum">{ t('vajinal_dogum') }</label>
          <select disabled id="vajinal_dogum" defaultValue={ bilgiler.vajinal_dogum } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="sezeryen">{ t('sezeryen') }</label>
          <select disabled id="sezeryen" defaultValue={ bilgiler.sezeryen } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="hormon_replasman_tedavisi">{ t('hormon_replasman_tedavisi') }</label>
          <select disabled id="hormon_replasman_tedavisi" defaultValue={ bilgiler.hormon_replasman_tedavisi } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="sigara_kullanim">{ t('sigara_kullanim') }</label>
          <select disabled id="sigara_kullanim" defaultValue={ bilgiler.sigara_kullanim } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="alkol_kullanim">{ t('alkol_kullanim') }</label>
          <select disabled id="alkol_kullanim" defaultValue={ bilgiler.alkol_kullanim } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="cinsel_aktif">{ t('cinsel_aktif') }</label>
          <select disabled id="cinsel_aktif" defaultValue={ bilgiler.cinsel_aktif } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="jinekolojik_hastalik">{ t('jinekolojik_hastalik') }</label>
          <select disabled id="jinekolojik_hastalik" defaultValue={ bilgiler.jinekolojik_hastalik } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="koah">{ t('koah') }</label>
          <select disabled id="koah" defaultValue={ bilgiler.koah } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="kalp_hastaligi">{ t('kalp_hastaligi') }</label>
          <select disabled id="kalp_hastaligi" defaultValue={ bilgiler.kalp_hastaligi } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="renal_hastalik">{ t('renal_hastalik') }</label>
          <select disabled id="renal_hastalik" defaultValue={ bilgiler.renal_hastalik } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="kabizlik">{ t('kabizlik') }</label>
          <select disabled id="kabizlik" defaultValue={ bilgiler.kabizlik } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="diyabet">{ t('diyabet') }</label>
          <select disabled id="diyabet" defaultValue={ bilgiler.diyabet } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="hipertansiyon">{ t('hipertansiyon') }</label>
          <select disabled id="hipertansiyon" defaultValue={ bilgiler.hipertansiyon } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="kognitif_bozukluk">{ t('kognitif_bozukluk') }</label>
          <select disabled id="kognitif_bozukluk" defaultValue={ bilgiler.kognitif_bozukluk } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="rekuren_iye">{ t('rekuren_iye') }</label>
          <select disabled id="rekuren_iye" defaultValue={ bilgiler.rekuren_iye } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="prolapsus">{ t('prolapsus') }</label>
          <select disabled id="prolapsus" defaultValue={ bilgiler.prolapsus } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="botoks">{ t('botoks') }</label>
          <select disabled id="botoks" defaultValue={ bilgiler.botoks } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="sns">{ t('sns') }</label>
          <select disabled id="sns" defaultValue={ bilgiler.sns } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="ptns">{ t('ptns') }</label>
          <select disabled id="ptns" defaultValue={ bilgiler.ptns } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="onceki_aam_tedavisi">{ t('onceki_aam_tedavisi') }</label>
          <select disabled id="onceki_aam_tedavisi" defaultValue={ bilgiler.onceki_aam_tedavisi } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="dm">{ t('dm') }</label>
          <select disabled id="dm" defaultValue={ bilgiler.dm } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="ht">{ t('ht') }</label>
          <select disabled id="ht" defaultValue={ bilgiler.ht } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="1">{ t("EVET") }</option>
          <option value="0">{ t("HAYIR") }</option>
          </select>
      </div>

      <div className="form-group">
          <label htmlFor="ikab">{ t('ikab') }</label>
          <select disabled id="ikab" defaultValue={ bilgiler.ikab } className="form-control">
          <option value="">{ t("secim_yapilmadi") }</option>
          <option value="NORMAL">{ t("NORMAL") }</option>
          <option value="ANORMAL">{ t("ANORMAL") }</option>
          </select>
      </div>

      </div>
    </div>
  )
}
