import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'
import ulkeler from '../../metinler/ulkeler.json'

import Yetkili from '../../Components/Yetkili'
import { api,server,prefix,endpoints } from '../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate, useParams } from 'react-router-dom'
const MySwal = withReactContent(Swal)

export default function Duzenle(props) {
  const { user,token } = useContext(MainContext)

  const { t }                               = useTranslation()
  const { kullanici_kodu : param_kk }       = useParams()
  const navigate                            = useNavigate()

  const [yetkili,setYetkili]                = useState(user);
  const [ad,setAd]                          = useState()
  const [soyad,setSoyad]                    = useState()
  const [rol,setRol]                        = useState()
  const [kk,setKK]                          = useState()
  const [ozetBilgiler,setOzetBilgiler]      = useState()
  const [telefon,setTelefon]                = useState()
  const [email,setEmail]                    = useState()
  const [ulke,setUlke]                      = useState()
  const [sehir,setSehir]                    = useState()
  const [cinsiyet,setCinsiyet]              = useState()

  const [unvan,setUnvan]                    = useState()
  const [hastane,setHastane]                = useState()

  const [telefonKodu,setTelefonKodu]        = useState("+")
  const [ulkeKodu,setUlkeKodu]              = useState("")
  const [dilKodu,setDilKodu]                = useState("")
  const [saatDilimi,setSaatDilimi]          = useState()
  const [sehirler,setSehirler]              = useState([])
  const [loading,setLoading]                = useState(false)
  
  const [contentLoading,setContentLoading]  = useState(false)
  const [kullaniciX,setKullaniciX]          = useState()

  const [arsivIslem,setArsivIslem]          = useState()
  const [arsivLoading,setArsivLoading]      = useState(false)

  const [hemenSilIslem,setHemenSillIslem]   = useState()
  const [hemenSilLoading,setHemenSilLoading]= useState(false)

  const [parolaSifirlaniyor,setParolaSifirlaniyor]  = useState(false)

  useEffect(() => {

    setContentLoading(true);
    let url = server + prefix + endpoints.kullanici_bilgi.replace(":id",param_kk);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      
      const { data : responseData } = response;
      const { hata, mesaj } = responseData;

      if(hata){
        MySwal.fire({
          allowOutsideClick : false,
          allowEscapeKey : false,
          titleText : mesaj,
          confirmButtonText : t('tamam')
        }).then((x) => {
          navigate("/dashboard")
      })

      }else{

        setContentLoading(false);
        const { data } = responseData;

        setKullaniciX(data);
        
        setYetkili({ id : data.yetkili_id, rol : data.yetkili_rolu, ad : data.yetkili_adi, soyad : "" })
        setAd(data.ad);
        setSoyad(data.soyad);
        setRol(data.rol);
        setCinsiyet(data.cinsiyet);
        setKK(data.kullanici_kodu);
        setOzetBilgiler(data.ozet_bilgiler);
        setTelefon(data.telefon);
        setEmail(data.email);
        ulkeSec(data.ulke,false)
        setSehir(data.sehir);
        setUnvan(data.unvan)
        setHastane(data.hastane)
      }

    }).catch((err) => {
      setContentLoading(false);
    })

  },[ ])

  const ulkeSec = (ulke, temizle = true) => {

    if(ulke == ""){
      setSehirler([])
      return;
    }

    setUlke(ulke);
    
    if(temizle){
      setSehir("");
    }

    const secilenUlke = ulkeler.find(x => x.adi == ulke);
    setTelefonKodu(secilenUlke.telefon_kodu)
    setSaatDilimi(secilenUlke.saat_dilimi)
    setUlkeKodu(secilenUlke.kod)
    setDilKodu(secilenUlke.dil_kodu)

    setSehirler(secilenUlke.sehirler);
  }

  const guncelle = () => {
    setLoading(true)

    const formData = {
      kullanici_kodu : kk,
      ad,
      soyad,
      rol,
      cinsiyet,
      email,
      ulke_kodu : ulkeKodu,
      dil_kodu : dilKodu,
      saat_dilimi : saatDilimi,
      telefon_kodu : telefonKodu,
      telefon,
      ozet_bilgiler : ozetBilgiler,
      ulke,
      sehir,
      yetkili_id : yetkili.id,
      unvan : rol == "SP" ? unvan : null,
      hastane : rol == "SP" ? hastane : null,
    }

    let url = server + prefix + endpoints.kullanici_guncelle.replace(":id",param_kk);
    api.request(url,"PUT",formData,{
      "Authorization" : "Bearer " + token
    }).then((response) => {

      setLoading(false)

      const { data : responseData } = response;
      const { hata,mesaj } = responseData;
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam')
      })

      if(!hata && param_kk != kk){
        navigate('/kullanici/goruntule/' + kk)
      }

    }).catch((err) => {
      setLoading(false)
    })
  }

  const parola_sifirla = () => {
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t("islemi_onayla"),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      if(x.isConfirmed){
        sifirla()
      }
    })
  }

  const sifirla = () => {
    setParolaSifirlaniyor(true);

    let url = server + prefix + endpoints.parola_gonder.replace(":id",kk);
    api.request(url,"POST",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { hata, mesaj } = response.data;

      setParolaSifirlaniyor(false);
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam'),
      })
    });
  }

  const arsivden_cikar = (kk) => {

    setArsivIslem(kk);
    setArsivLoading(true);

    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setArsivLoading(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_arsivden_cikar.replace(":id",kk);
        api.request(url,"POST",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          if(!hata){
            const kullaniciY = { ...kullaniciX };
            kullaniciY.arsive_alindi = false;
            setKullaniciX(kullaniciY);
          }

        });

      }
    })
  }

  const hemen_sil = (kk) => {

    setHemenSillIslem(kk);
    setHemenSilLoading(true);

    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setHemenSilLoading(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_sil.replace(":id",kk);
        api.request(url,"DELETE",{ hemen_sil : true },{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          if(!hata){
            navigate("/kullanici/listele")
          }

        });

      }
    })
  }

  return (
    <Layout baslik={ t('kullanici_goruntule') }>
        <div className="row">
          <div className="col-md-8">

            {
              (kullaniciX != undefined && kullaniciX.arsive_alindi) ?
              <>
              <div className="alert alert-danger">
                <p>{ t('arsive_alinan_kullanici') }</p>
                <p>{ t('arsive_alinma_tarihi') } : { kullaniciX.arsive_alinma_tarihi }</p>

                <button onClick={() => arsivden_cikar(kullaniciX.kullanici_kodu)} className="btn btn-outline-dark">
                  {
                    (arsivLoading && arsivIslem == kullaniciX.kullanici_kodu) ?
                    <>
                      <i className="fas fa-spinner fa-spin"></i>
                    </>
                    :
                    <>
                      <i className="fas fa-reply-all"></i> { t('arsivden_cikar') }
                    </>
                  }
                </button>

                {
                  user.rol == "ADMIN" &&
                  <>
                  <button onClick={() => hemen_sil(kullaniciX.kullanici_kodu)} className="btn btn-outline-dark ml-1">
                    {
                      (hemenSilLoading && hemenSilIslem == kullaniciX.kullanici_kodu) ?
                      <>
                        <i className="fas fa-spinner fa-spin"></i>
                      </>
                      :
                      <>
                        <i className="fas fa-trash-alt"></i> { t('hemen_sil') }
                      </>
                    }
                  </button>
                  </>
                }
              </div>
              </>
              :
              <></>
            }

            <div className="card shadow">
              <div className="card-body">

                {
                  contentLoading ?
                  <>
                    <div className="text-center mt-5">
                      <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                      <p>{ t("yukleniyor...") }</p>
                    </div>
                  </>
                  :
                  <>
                  <div className="row">
                    <div className="col-md-6">

                      {
                        (user.kullanici_kodu != param_kk && user.rol != "SUBADMIN") &&
                        <>
                          <Yetkili yetkili={ yetkili } setYetkili={ setYetkili } rol={ rol }/>
                        </>
                      }

                      <div className="row">
                        <div className="col-md-6">

                          <div className="form-group">
                            <label htmlFor="ad"><small className="text-danger">*</small> { t('ad') }</label>
                            <input id="ad" type="text" className="form-control" value={ ad } onChange={(e) => setAd(e.target.value)} />
                          </div>

                        </div>
                        <div className="col-md-6">

                          <div className="form-group">
                            <label htmlFor="soyad"><small className="text-danger">*</small> { t('soyad') }</label>
                            <input id="soyad" type="text" className="form-control" value={ soyad } onChange={(e) => setSoyad(e.target.value)} />
                          </div>

                        </div>
                      </div>
                  
                      <div className="form-group">
                        <label htmlFor="cinsiyet"><small className="text-danger">*</small> { t('cinsiyet') }</label>
                        <select defaultValue="" name="cinsiyet" id="cinsiyet" className="form-control" value={ cinsiyet } onChange={ (e) => setCinsiyet(e.target.value) }>
                          <option value="" disabled>{ t("secim_yapilmadi") }</option>
                          <option value="KADIN">{ t('KADIN') }</option>
                          <option value="ERKEK">{ t('ERKEK') }</option>
                        </select>
                      </div>
                    
                      <div className="form-group">
                        <label htmlFor="rol"><small className="text-danger">*</small> { t('rol') }</label>
                        <select defaultValue="" name="rol" id="rol" className="form-control" value={ rol } onChange={ (e) => setRol(e.target.value) }>
                          <option value="" disabled>{ t("secim_yapilmadi") }</option>
                          {
                            user.rol == "ADMIN" &&
                            <>
                            <option value="ADMIN">{ t('ADMIN') }</option>
                            <option value="SUBADMIN">{ t('SUBADMIN') }</option>
                            </>
                          }
                          <option value="SP">{ t('SP') }</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="kk"><small className="text-danger">*</small> { t('kullanici_kodu') }</label>
                        <input id="kk" type="text" className="form-control" value={ kk } onChange={(e) => setKK(e.target.value)} autoComplete="new-password"/>
                      </div>
                    </div>

                    <div className="col-md-6">

                      <div className="form-group">
                        <label htmlFor="ulke"><small className="text-danger">*</small> { t('ulke') }</label>
                        <select name="ulke" id="ulke" className="form-control" value={ ulke } onChange={ (e) => ulkeSec(e.target.value) }>
                          <option value="" disabled>{ t("secim_yapilmadi") }</option>
                          {
                            ulkeler.map(ulke => (
                              <option key={ ulke.adi } value={ ulke.adi }>{ ulke.adi }</option>
                            ))
                          }
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="sehir"><small className="text-danger">*</small> { t('sehir') }</label>
                        <select name="sehir" id="sehir" className="form-control" value={ sehir } onChange={(e) => setSehir(e.target.value)}>
                          <option value="" disabled>{ t("secim_yapilmadi") }</option>
                          {
                            sehirler.map(sehir => (
                              <option key={ sehir } value={ sehir }>{ sehir }</option>
                            ))
                          }
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="telefon"><small className="text-danger">*</small> { t("telefon_numarasi") }</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="telefon">{ telefonKodu }</span>
                          </div>
                          <input id="telefon" type="text" className="form-control" value={ telefon } onChange={(e) => setTelefon(e.target.value)} />
                        </div>
                      </div>

                      <div className="form-group">
                          <label htmlFor="email">{ t('email') }</label>
                          <input id="email" type="text" className="form-control" value={ email } onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      {
                        (rol == "SP") &&
                        <>
                        <div className="form-group">
                          <label htmlFor="unvan">{ t('unvan') }</label>
                          <input id="unvan" type="text" className="form-control" value={ unvan } onChange={(e) => setUnvan(e.target.value)} autoComplete="new-password"/>
                        </div>
                        </>
                      }

                    </div>

                    <div className="col-md-12">

                      {
                        (rol == "SP") &&
                        <>
                        <div className="form-group">
                          <label htmlFor="hastane">{ t('hastane') }</label>
                          <textarea id="hastane" rows={ 3 } type="text" className="form-control" value={ hastane } onChange={(e) => setHastane(e.target.value)}></textarea>
                        </div>
                        </>
                      }

                      <div className="form-group">
                        <label htmlFor="ozetBilgiler">{ t('ozet_bilgiler') }</label>
                        <textarea id="ozetBilgiler" rows={ 3 } type="text" className="form-control" value={ ozetBilgiler == null ? "" : ozetBilgiler } onChange={(e) => setOzetBilgiler(e.target.value)}></textarea>
                      </div>

                      <button onClick={() => parola_sifirla()} className="btn btn-danger mb-2">
                        {
                          parolaSifirlaniyor ? 
                          <>
                            <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                          </>
                          :
                          <>
                            <i className="fas fa-lock"></i> { t("parola_sifirla") }
                          </>
                        }
                      </button>

                      <button className="btn btn-dark btn-block btn-lg" onClick={() => guncelle()}>
                        {
                          loading ? 
                          <>
                            <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                          </>
                          :
                          <>
                            <i className="fas fa-check"></i> { t("guncelle") }
                          </>
                        }
                      </button>
                    </div>
                  </div>
                  </>
                }

              </div>
            </div>

          </div>
        </div>
    </Layout>
  )
}
