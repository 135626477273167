import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import '../metinler/i18n'

import { api,server,prefix,endpoints } from '../Main/Api'

import BG from '../assets/background-green.jpg'
import { MainContext,useContext } from '../Main/MainContext'
import FLAG_TR from '../../src/assets/tr.svg'
import FLAG_EN from '../../src/assets/en.svg'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom';
const MySwal = withReactContent(Swal)

export default function ParolamiUnuttum(){


    const { setDil } = useContext(MainContext)
    const { t } = useTranslation();

    const [ kk,setKK ]                          = useState('')
    const [ telefon,setTelefon ]                = useState('')

    const dilDegistir = (dil) => {
        setDil(dil)
    }

    const sifirla = () => {

        MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : t('isleniyor'),
            didOpen: () => {
                MySwal.showLoading();
            },
        })

        const url = server + prefix + endpoints.parola_sifirla;
        api.request(url,'POST',{
            kullanici_kodu : kk,
            telefon
        }).then((response) => {
            
            let { data : responseData } = response;

            const { hata,mesaj } = responseData;

            if(!hata){
                setKK("")
                setTelefon("")
            }

            MySwal.fire({
                allowOutsideClick : false,
                allowEscapeKey : false,
                titleText : mesaj,
                confirmButtonText : t('tamam')
            })
            
        }).catch((err) => {
            MySwal.close();
        })
        
    }

    return (
        <div className="mainContainerStyle" style={ mainContainerStyle }>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-left" style={ { minHeight : '100vh' } }>
                    <div className="col-md-6">
                        <div className="card shadow">
                            <div className="card-body">

                                <h3>{ t('parola_sifirla') }</h3>
                                <hr />

                                <div className="form-group">
                                    <label htmlFor="kullanici_kodu">{ t("kullanici_kodu") }</label>
                                    <input value={ kk } onChange={(e) => setKK(e.target.value)} type="text" className="form-control" autoComplete='new-password'/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="telefon">{ t("telefon_son_4") }</label>
                                    <input value={ telefon } maxLength={ 4 } onChange={(e) => setTelefon(e.target.value)} type="text" className="form-control" autoComplete='new-password'/>
                                </div>
                                
                                <Link to="/">{ t("giris_yap") }</Link>

                                <div className="text-right">
                                    <button onClick={() => sifirla() } className="btn btn-dark">
                                    { t("gonder") }
                                    </button>
                                </div>

                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('tr')} href="#">
                                        <img src={ FLAG_TR } style={ { width : 32 } } />
                                    </button>
                                    <button className="nav-link btn btn-light" onClick={() => dilDegistir('en')} href="#">
                                        <img src={ FLAG_EN } style={ { width : 32 } } />
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="text-center mt-3" style={ { fontSize : "1.4rem" } }>
                            <strong>Smart</strong>Uric
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mainContainerStyle = {
    backgroundImage : `url(${BG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height : '100vh'
};