import React, { useEffect, useState } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom'

import { api,server,prefix,endpoints } from '../../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Kullanicilar() {

  const { user,token }    = useContext(MainContext)
  const { t }             = useTranslation()
  const navigate          = useNavigate()
  const { kullanici_kodu }= useParams()

  const [kullanicilar,setKullanicilar]          = useState([])
  const [sayfa,setSayfa]                        = useState(1)
  const [sonSayfa,setSonSayfa]                  = useState(1)
  const [ara,setAra]                            = useState()
  const [loading,setLoading]                    = useState(false)
  const [siliniyor,setSiliniyor]                = useState(false);
  const [silinenKullanici,setSilinenKullanici]  = useState();
  const [rol,setRol]                            = useState();
  const [arsiv,setArsiv]                        = useState();

  useEffect(() => {
    listele()
  },[sayfa])

  const ileri = () => {
    if(sayfa >= sonSayfa) return;
    
    const currentPage = sayfa;
    setSayfa(currentPage + 1)
  }

  const geri = () => {
    if(sayfa < 2) return;

    const currentPage = sayfa
    setSayfa(currentPage - 1);
  }

  const sil = (kk) => {

    setSilinenKullanici(kk);
    setSiliniyor(true);
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('silme_islemini_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setSiliniyor(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_sil.replace(":id",kk);
        api.request(url,"DELETE",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          const kullanicilarX = [ ...kullanicilar ];
          kullanicilarX.find(x => x.kullanici_kodu == kk).arsive_alindi = true;
          setKullanicilar(kullanicilarX);

        });

      }
    })
  }

  const arsivden_cikar = (kk) => {

    setSilinenKullanici(kk);
    setSiliniyor(true);
    MySwal.fire({
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('islemi_onayla'),
      confirmButtonText : t('tamam'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      setSiliniyor(false)
      if(x.isConfirmed){

        let url = server + prefix + endpoints.kullanici_arsivden_cikar.replace(":id",kk);
        api.request(url,"POST",null,{
          "Authorization" : "Bearer " + token
        }).then((response) => {

          const { hata,mesaj } = response.data;

          MySwal.fire({
            allowOutsideClick : false,
            allowEscapeKey : false,
            titleText : mesaj,
            confirmButtonText : t('tamam'),
          })

          const kullanicilarX = [ ...kullanicilar ];
          kullanicilarX.find(x => x.kullanici_kodu == kk).arsive_alindi = false;
          setKullanicilar(kullanicilarX);

        });

      }
    })
  }

  const listele = () => {
    setLoading(true)
    const url = server + prefix + endpoints.kullanici_listele;
    api.request(url,"GET",{
      ara,
      page : sayfa,
      rol,
      arsiv,
      kullanici_kodu
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data : responseData } = response;

      const { sayfa,son_sayfa } = responseData;
      
      setSonSayfa(son_sayfa);
      setSayfa(sayfa)

      setKullanicilar(responseData.data);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  const btnAra = () => {
    setSayfa(1);
    setTimeout(() => {
      listele();
    }, 250);
  }

  return (
    <div>

      <div className="row">

        <div className="col-md-8">
          <input onKeyDown={(e) => { if(e.key === 'Enter') btnAra() }} name="ara" id="ara" text={ ara } placeholder={ t('kullanici_ara_placeholder') } onChange={(e) => setAra(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="col-md-4 d-flex">
          <button onClick={() => btnAra()} className="btn btn-outline-dark w-100">
            <i className="fas fa-search"></i> { t("ara") }
          </button>

          <button data-target="#filtreler" data-toggle="collapse" className="btn btn-outline-dark w-100 ml-2">
            <i className="fas fa-filter"></i> { t("filtrele") }
          </button>
        </div>

        <div className="col-md-12 collapse mt-2" id="filtreler">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="rol">{ t('rol') }</label>
                  <select defaultValue="" name="rol" id="rol" value={ rol } onChange={(e) => setRol(e.target.value)} className="form-control">
                    <option value="">{ t('hepsi') }</option>
                    <option value="ADMIN">{ t('ADMIN') }</option>
                    <option value="SUBADMIN">{ t('SUBADMIN') }</option>
                    <option value="SP">{ t('SP') }</option>
                  </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="arsiv">{ t('arsiv') }</label>
                  <select defaultValue="" name="arsiv" id="arsiv" value={ arsiv } onChange={(e) => setArsiv(e.target.value)}  className="form-control">
                    <option value="">{ t('hepsi') }</option>
                    <option value="1">{ t('evet') }</option>
                    <option value="0">{ t('hayir') }</option>
                  </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        loading ?
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("Yükleniyor...") }</p>
          </div>
        </>
        :
        <>
        <div className="table-responsive mt-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>{ t('ad_soyad') }</th>
                <th className='text-center'>{ t('rol') }</th>
                <th className='text-center'>{ t('kullanici_kodu') }</th>
                <th className='text-center'>{ t('ulke') }</th>
                <th className='text-center'>{ t('sehir') }</th>
                <th className='text-center'>{ t('yetkili') }</th>
                <th className='text-center'>{ t('kayit_tarihi') }</th>
                <th className='text-center'>{ t('aksiyon') }</th>
              </tr>
            </thead>
            <tbody>
              {
                kullanicilar.map((kullanici,index) => (
                  <tr key={ index.toString() }>
                    <td className='text-center'>{ index + 1 }</td>
                    <td className='text-center'>
                      <Link to={ "/kullanici/goruntule/" + kullanici.kullanici_kodu }>
                        { kullanici.ad + " " + kullanici.soyad }
                      </Link>
                    </td>
                    <td className='text-center'>{ t(kullanici.rol) }</td>
                    <td className='text-center'>{ kullanici.kullanici_kodu }</td>
                    <td>{ kullanici.ulke }</td>
                    <td>{ kullanici.sehir }</td>
                    <td className='text-center'>
                      <Link to={ "/kullanici/goruntule/" + kullanici.yetkili_kullanici_kodu }>
                        { kullanici.yetkili_adi + ( " (" + t(kullanici.yetkili_rolu) + ")" ) + ( kullanici.yetkili_id == user.id ? ' (' + t('siz') + ')' : '' ) }
                      </Link>
                    </td>
                    <td className='text-center'>{ kullanici.kayit_tarihi }</td>
                    <td className='text-center'>
                      {
                        kullanici.arsive_alindi ?
                        <>
                        <button onClick={() => arsivden_cikar(kullanici.kullanici_kodu)} className="btn btn-outline-warning">
                          {
                            (siliniyor && silinenKullanici == kullanici.kullanici_kodu) ?
                            <>
                              <i className="fas fa-spinner fa-spin"></i>
                            </>
                            :
                            <>
                              <i className="fas fa-reply-all"></i> { t('arsivden_cikar') }
                            </>
                          }
                        </button>
                        </>
                        :
                        <>
                        <button onClick={() => sil(kullanici.kullanici_kodu)} className="btn btn-outline-danger">
                          {
                            (siliniyor && silinenKullanici == kullanici.kullanici_kodu) ?
                            <>
                              <i className="fas fa-spinner fa-spin"></i>
                            </>
                            :
                            <>
                              <i className="fas fa-trash-alt"></i> { t('sil') }
                            </>
                          }
                        </button>
                        </>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="text-right">
            <button onClick={() => geri()} className="btn btn-dark mr-1" disabled={ sayfa < 2 ? true : false }>
              <i className="fas fa-arrow-left"></i>
            </button>
            <span>{ sayfa + "/" + sonSayfa }</span>
            <button onClick={() => ileri()} className="btn btn-dark ml-2" disabled={ sayfa >= sonSayfa ? true : false }>
              <i className="fas fa-arrow-right"></i>
            </button>
        </div>
        </>
      }
        
    </div>
  )
}
