import tr from './tr'
import en from './en'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  tr,
  en
}

i18n.use(initReactI18next).init({
  resources,
  lng : "tr",
  keySeparator : false,
  interpolation : {
    escapeValue : false
  }
})

export default i18n