export default {
    translation : {
        "kullanici_kodu" : "User Code",
        "parola" : "Password",
        "giris_yap" : "Log In",
        "isleniyor" : "Processing...",
        "tamam" : "OK",
        "adminler" : "Admins",
        "sub_adminler" : "SubAdmins",
        "spler" : "Healthcare Professionals",
        "hastalar" : "Patients",
        "kullanicilar" : "Users",
        "receteler" : "Prescriptions",
        "recete_olustur" : "Create Prescription",
        "receteleri_goruntule" : "View Prescriptions",
        "recete_ayarlari" : "Prescription Settings",
        "yetkiler" : "Permissions",
        "cikis_yap" : "Log Out",
        "yeni_olustur" : "Create New",
        "kullanici_olustur" : "Create User",
        "ad" : "First Name",
        "soyad" : "Last Name",
        "ozet_bilgiler" : "Summary Information",
        "email" : "Email",
        "telefon_numarasi" : "Phone Number",
        "ulke" : "Country",
        "sehir" : "City",
        "secim_yapilmadi" : "No Selection",
        "kayit_tarihi" : "Registration Date",
        "duzenle" : "Edit",
        "sil" : "Delete",
        "ara" : "Search",
        "kullanici_ara_placeholder" : "You can search by first name, last name, user code, email address, or phone number.",
        "kullanici_goruntule" : "View User",
        "guncelle" : "Update",
        "parola_bilgilendirme" : "Leave empty if you don't want to change it.",
        "silme_islemini_onayla" : "Confirm Deletion",
        "vazgec" : "Cancel",
        "aksiyon" : "Action",
        "arsivden_cikar" : "Restore from Archive",
        "islemi_onayla" : "Do you want to continue?",
        "arsive_alinan_kullanici" : "This user has been archived and is scheduled for automatic removal from the system 30 days after being archived.",
        "arsive_alinma_tarihi" : "Archive Date",
        "rol" : "Role",
        "ADMIN" : "Admin",
        "SUBADMIN" : "SubAdmin",
        "SP" : "Healthcare Professional",
        "HASTA" : "Patient",
        "filtrele" : "Filter",
        "belirtilmedi" : "Not Specified",
        "evet" : "Yes",
        "hayir" : "No",
        "arsiv" : "Archive",
        "hepsi" : "All",
        "kriter" : "Criteria",
        "en_az" : "Minimum",
        "en_fazla" : "Maximum",
        "tedavi_suresi_dk" : "Treatment Duration (minutes)",
        "hafta_sayisi" : "Number of Weeks",
        "haftadaki_siklik" : "Weekly Frequency",
        "darbe_genisligi" : "Pulse Width",
        "frekans" : "Frequency",
        "mikro_sn" : "Microseconds",
        "parola_sms_olacak_gonderilecek" : "Password will be sent via SMS.",
        "KADIN" : "Female",
        "ERKEK" : "Male",
        "cinsiyet" : "Gender",
        "parola_sifirla" : "Reset Password (Sent via SMS)",
        "hasta_olustur" : "Create Patient",
        "dogum_tarihi" : "Date of Birth",
        "genel_bilgiler" : "General Information",
        "hastanin_oykusu" : "Patient History",
        "cihaz_seri_no" : "Device Serial Number",
        "boy" : "Height",
        "kilo" : "Weight",
        "menapoz_sure" : "Menopausal Duration",
        "q_tipi_testi" : "Q-Type Test",
        "pad_testi" : "Pad Test",
        "tv_usg" : "TV Ultrasound",
        "bmi" : "BMI",
        "residu_idrar_volumu" : "Residual Urine Volume",
        "sikayeti" : "Complaint",
        "idrar_inkontinans_oykusu" : "Urinary Incontinence History",
        "dogum_sayisi_ve_sekli" : "Number and Method of Childbirth",
        "gecirdigi_operasyonlar" : "Past Surgeries",
        "kullandigi_ilaclar" : "Medications Used",
        "iri_dogum_oykusu" : "Large Birth History",
        "urogram" : "Urogram",
        "urodinamik_tetkik" : "Urodynamic Study",
        "sigara_bilgi" : "Smoking Information",
        "son_adet_tarihi" : "Last Menstrual Period",
        "medeni_hal" : "Marital Status",
        "meslek" : "Occupation",
        "vajinal_dogum" : "Vaginal Delivery",
        "sezeryen" : "Cesarean Section",
        "hormon_replasman_tedavisi" : "Hormone Replacement Therapy",
        "sigara_kullanim" : "Smoking Use",
        "alkol_kullanim" : "Alcohol Use",
        "cinsel_aktif" : "Sexual Activity",
        "jinekolojik_hastalik" : "Gynecological Disease",
        "koah" : "COPD",
        "kalp_hastaligi" : "Heart Disease",
        "renal_hastalik" : "Renal Disease",
        "kabizlik" : "Constipation",
        "diyabet" : "Diabetes",
        "hipertansiyon" : "Hypertension",
        "kognitif_bozukluk" : "Cognitive Impairment",
        "rekuren_iye" : "Recurrent UTIs",
        "prolapsus" : "Prolapse",
        "botoks" : "Botox",
        "sns" : "SNS",
        "ptns" : "PTNS",
        "onceki_aam_tedavisi" : "Previous AAM Treatment",
        "dm" : "DM (Diabetes Mellitus)",
        "ht" : "HT (Hypertension)",
        "ikab" : "IKAB (Chronic Renal Insufficiency)",
        "BEKAR" : "Single",
        "EVLI" : "Married",
        "DUL" : "Divorced",
        "CALISIYOR" : "Employed",
        "CALISMIYOR" : "Unemployed",
        "EMEKLI" : "Retired",
        "NORMAL" : "Normal",
        "ANORMAL" : "Abnormal",
        "hasta_goruntule" : "View Patient",
        "sozlesme_kabul_edilmedi" : "You must accept GDPR agreements to log in.",
        "parolami_unuttum" : "Forgot Password",
        "telefon_son_4" : "Last 4 Digits of Your Phone Number",
        "parola_sifirla" : "Reset Password",
        "gonder" : "Send",
        "tedavi_protokolu" : "Treatment Protocol",
        "frekans_hz" : "Frequency (Hz)",
        "dbg_mikro_sn" : "Pulse Width (Microseconds)",
        "SAG" : "Right Foot",
        "SOL" : "Left Foot",
        "CIFT" : "Both Feet",
        "ayak" : "Foot",
        "recete_adi" : "Prescription Name",
        "genel_recete" : "General Prescription (For Everyone)",
        "yetkili_secim_ekrani" : "Authorized Selection Screen",
        "yetkili_secim_ekrani_bilgi" : "In this screen, you can view and select your subordinate users.",
        "yetkili" : "Authorized",
        "kendiniz" : "Yourself",
        "yetkili_ara_plc" : "Search by first name, last name, email, phone, or user code.",
        "yukleniyor" : "Loading...",
        "ad_soyad" : "Full Name",
        "sec" : "Select",
        "telefon" : "Phone",
        "siz" : "You",
        "seans_durumu" : "Session Status",
        "tedavi_durumu" : "Treatment Status",
        "unvan" : "Title",
        "hastane" : "Hospital",
        "hemen_sil" : "Delete Immediately",
        "hasta_bilgileri" : "PATIENT INFORMATION",
        "tedavi_bilgileri" : "TREATMENT INFORMATION",
        "hasta_duzenle" : "Edit Patient",
        "goruntule" : "View",
        "olusturulma_tarihi" : "Creation Date",
        "recete_duzenle" : "Edit Prescription",
        "listele" : "List",
        "ayarlar" : "Settings",
        "tp_duzenle" : "Edit Treatment Protocol",
        "tp_adi" : "Treatment Protocol Name",
        "tp_olustur" : "Create Treatment Protocol",
        "tedavi_protokolleri" : "Treatment Protocols",
        "tp_ayarlari" : "Treatment Protocol Settings",
        "yetkilisi_oldugu_kullanicilar" : "USERS UNDER YOUR AUTHORITY",
        "tedavi_ata" : "Assign Treatment",
        "recete_ara_plc" : "You can search by treatment protocol name",
        "tarih" : "Date",
        "kvkk_kabul" : "I accept the KVKK agreement",
    }
}