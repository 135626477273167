import React, { useState,useEffect } from 'react'
import { MainContext,useContext } from '../../../Main/MainContext'
import Layout from '../../../Components/Layout'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom'

import Modal from 'react-modal';

import { api,server,prefix,endpoints } from '../../../Main/Api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function TedaviGoruntule() {

  const { user,token }        = useContext(MainContext)
  const { t }                 = useTranslation()
  const [loading,setLoading]  = useState(false)

  const [ayarlar,setAyarlar]  = useState(null)
  const [tedavi,setTedavi]    = useState()
  const [hasta,setHasta]      = useState()
  const [takvim,setTakvim]    = useState()
  const [open,setOpen]        = useState(false)

  const [tarih,setTarih]      = useState()
  const [aksiyon,setAksiyon]  = useState()

  const [aksiyonEkleniyor,setAEkleniyor]  = useState(false)

  const { id }                = useParams()

  useEffect(() => {
    tedavi_bilgileri()
  },[ ])

  const closeModal = () => {
    if(!aksiyonEkleniyor){
      setOpen(false)
    }
  }

  const tedavi_bilgileri = () => {
    setLoading(true)
    let url = server + prefix + endpoints.tedavi_bilgileri.replace(":id",id);
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data } = response;

      setAyarlar(data.ayarlar)
      setTedavi(data.tedavi)
      setHasta(data.hasta)
      setTakvim(data.takvim)
      
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  const aksiyonEkle = () => {
    setAEkleniyor(true)
    let url = server + prefix + endpoints.aksiyon_ekle;
    api.request(url,"POST",{
      tedavi_id : tedavi.id,
      tarih,
      aksiyon
    },{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data } = response;
      
      MySwal.fire({
        title : data.mesaj,
        icon : data.hata ? "warning" : "success",
        confirmButtonText : t('tamam'),
      }).then(x => {
        if(x.isConfirmed){
          if(!data.hata){
            tedavi_bilgileri()
            setOpen(false)
          }
        }
      })
      
      setAEkleniyor(false)
    }).catch((err) => {
      setAEkleniyor(false)
    })
  }

  const aksiyonSil = (id) => {

    let url = server + prefix + endpoints.aksiyon_sil.replace(":id",id);
    api.request(url,"DELETE",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      const { data } = response;

      if(!data.hata){
        let takvimX = [ ...takvim ];
        takvimX = takvimX.filter(x => x.id != id);
        setTakvim(takvimX);
      }
      
      MySwal.fire({
        title : data.mesaj,
        icon : data.hata ? "warning" : "success",
        confirmButtonText : t('tamam'),
      })

    }).catch((err) => {
    })
  }

  const btnAksiyonSil = (id) => {
    MySwal.fire({
      icon : "warning",
      allowOutsideClick : false,
      allowEscapeKey : false,
      titleText : t('silme_islemini_onayla'),
      confirmButtonText : t('evet'),
      cancelButtonText : t('vazgec'),
      showCancelButton : true
    }).then((x) => {
      if(x.isConfirmed){
        aksiyonSil(id)
      }
    });
  }

  return (
    <Layout baslik={ t('tedavi_goruntule') }>
      
      <Modal
          isOpen={ open }
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal">
            
            <div>

              <h4 className="text-danger mb-3">{ t('manuel_aksiyon_ekle') }</h4>

              <div className="form-group">
                <label htmlFor="tarih">{ t('aksiyon_tarihi') }</label>
                <input id="tarih" type="date" value={ tarih } onChange={(e) => setTarih(e.target.value)} className="form-control" />
              </div>

              <select id="aksiyon" defaultValue="" value={ aksiyon } onChange={(e) => setAksiyon(e.target.value)} className="form-control">
                <option value="" disabled>{ t('secim_yapilmadi') }</option>
                <option value="URINER">{ t('URINER') }</option>
                <option value="ANKET">{ t('ANKET') }</option>
                <option value="TEDAVI">{ t('TEDAVI') }</option>
              </select>

              <div className="text-right mt-3">
                <button onClick={() => aksiyonEkle()} className="btn btn-success">
                  {
                    aksiyonEkleniyor ?
                    <>
                    <i className="fas fa-spinner fa-spin"></i>
                    </>
                    :
                    <>
                    <i className="fas fa-check"></i> { t('aksiyon_ekle') }
                    </>
                  }
                </button>
              </div>
            </div>

      </Modal>

      {
        (loading || ayarlar == null || tedavi == null || hasta == null ||takvim == null) ?
        <>
          <div className="text-center mt-5">
            <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
            <p>{ t("yukleniyor...") }</p>
          </div>
        </>
        :
        <>
        <div className="row">

            <div className="col-md-12 col-12">
                <div className="card shadow">
                    <div className="card-body">
                        
                        <div className="row">

                          <div className="col-md-6">
                            <h4 className='mb-3 text-danger'>{ t('hasta_bilgileri') }</h4>

                            <div className="form-group">
                              <label>{ t('hasta_adi') }</label>
                              <input type="text" className="form-control" value={ hasta.ad + " " + hasta.soyad } disabled/>
                            </div>

                            <div className="form-group">
                              <label>{ t('yetkili') }</label>
                              <input type="text" className="form-control" value={ hasta.yetkili_adi } disabled/>
                            </div>

                            <div className="form-group">
                              <label>{ t('dogum_tarihi') }</label>
                              <input type="text" className="form-control" value={ hasta.dogum_tarihi } disabled/>
                            </div>

                            <div className="form-group">
                              <label>{ t('cinsiyet') }</label>
                              <input type="text" className="form-control" value={ t(hasta.cinsiyet) } disabled/>
                            </div>

                            <div className="form-group">
                              <label>{ t('ozet_bilgiler') }</label>
                              <textarea className="form-control" rows={6} value={ t(hasta.ozet_bilgiler) } disabled></textarea>
                            </div>
                          </div>

                          <div className="col-md-6">

                            <h4 className='mb-3 text-danger'>{ t('ref_tp_bilgileri').toUpperCase() }</h4>

                            <div>
                                <div className="form-group">
                                    <label htmlFor="recete_adi">{ t('tp_adi') }</label>
                                    <input id="recete_adi" value={ tedavi.referans_recete_adi } type="text" className="form-control" disabled/>
                                </div>
                              
                                <div className="form-group">
                                    <label htmlFor="ayak">{ t('ayak') }</label>
                                    <select defaultValue={ tedavi.uygulanacak_ayak } id="ayak" className="form-control" disabled>
                                        <option value="">{ t('secim_yapilmadi') }</option>
                                        <option value="SOL">{ t('SOL') }</option>
                                        <option value="SAG">{ t('SAG') }</option>
                                        <option value="CIFT">{ t('CIFT') }</option>
                                    </select>
                                </div>
                              
                                <div className="form-group">
                                        <div style={ { display:'flex' } }>
                                            <label htmlFor="hafta_sayisi" style={{ flex : 1 }}>{ t('hafta_sayisi') }</label>
                                            <label htmlFor="hafta_sayisi">{ tedavi.hafta_sayisi }</label>
                                        </div>
                                        <input id="hafta_sayisi" type="range" className="custom-range" value={ tedavi.hafta_sayisi } min={ ayarlar.en_az_hafta_sayisi } max={ ayarlar.en_fazla_hafta_sayisi } step="1"/>
                                    </div>
                              
                                    <div className="form-group">
                                        <div style={ { display:'flex' } }>
                                            <label htmlFor="haftadaki_siklik" style={{ flex : 1 }}>{ t('haftadaki_siklik') }</label>
                                            <label htmlFor="haftadaki_siklik">{ tedavi.siklik }</label>
                                        </div>
                                        <input id="haftadaki_siklik" type="range" className="custom-range" value={ tedavi.siklik } min={ ayarlar.en_az_haftadaki_siklik } max={ ayarlar.en_fazla_haftadaki_siklik } step="1"/>
                                    </div>
                              
                                    <div className="form-group">
                                        <div style={ { display:'flex' } }>
                                            <label htmlFor="tedavi_suresi" style={{ flex : 1 }}>{ t('tedavi_suresi_dk') }</label>
                                            <label htmlFor="tedavi_suresi">{ tedavi.tedavi_suresi }</label>
                                        </div>
                                        <input id="tedavi_suresi" type="range" className="custom-range" value={ tedavi.tedavi_suresi } min={ ayarlar.en_az_tedavi_suresi } max={ ayarlar.en_fazla_tedavi_suresi } step="1" />
                                    </div>
                              
                                    <div className="form-group">
                                        <div style={ { display:'flex' } }>
                                            <label htmlFor="frekans" style={{ flex : 1 }}>{ t('frekans_hz') }</label>
                                            <label htmlFor="frekans">{ tedavi.frekans }</label>
                                        </div>
                                        <input id="frekans" type="range" className="custom-range" value={ tedavi.frekans } min={ ayarlar.en_az_frekans } max={ ayarlar.en_fazla_frekans } step="1"/>
                                    </div>
                              
                                    <div className="form-group">
                                        <div style={ { display:'flex' } }>
                                            <label htmlFor="darbe_genisligi" style={{ flex : 1 }}>{ t('dbg_mikro_sn') }</label>
                                            <label htmlFor="darbe_genisligi">{ tedavi.darbe_genisligi }</label>
                                        </div>
                                        <input id="darbe_genisligi" type="range" className="custom-range" value={ tedavi.darbe_genisligi } min={ ayarlar.en_az_darbe_genisligi } max={ ayarlar.en_fazla_darbe_genisligi } step={ 20 }/>
                                    </div>
                              
                            </div>

                          </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="col-md-12 col-12 mt-3">
              <div className="card shadow">
                <div className="card-body">


                  <div className="table-responsive mb-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className='text-center'>{ t("tedavi_durumu2") }</th>
                          <th className='text-center'>{ t("tedavi_surdurme_basarisi") }</th>
                          <th className='text-center'>{ t("toplam_gecikme") }</th>
                          <th className='text-center'>{ t("tedavi_basarisi") }</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='text-center'>{ t(tedavi.durum).toUpperCase() }</td>
                          <td className='text-center'>{ t(tedavi.str_tedavi_surdurme_basarisi).toUpperCase() }</td>
                          <td className='text-center'>{ tedavi.toplam_gecikme + " " + t('gun') }</td>
                          <td className='text-center'>{ t(tedavi.basari).toUpperCase() }</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h4 className="text-danger mb-3">{ t('tedavi_takvimi') }</h4>

                  <button onClick={() => setOpen(true)} className="btn btn-dark mb-3">
                    <i className="fas fa-plus"></i> { t('manuel_aksiyon_ekle') }
                  </button>

                  <div className="table-responsive">
                      <table className="table table-striped">
                          <thead>
                              <tr>
                                  <th className='text-center'>#</th>
                                  <th className='text-center'>{ t('planlanan_tarih') }</th>
                                  <th className='text-center'>{ t('aksiyon') }</th>
                                  <th className='text-center'>{ t('tamamlandi') }</th>
                                  <th className='text-center'>{ t('anket_basarisi') }</th>
                                  <th className='text-center'>{ t('tedavi_surdurme_basarisi2') }</th>
                                  <th className='text-center'>{ t('anket_skoru') }</th>
                                  <th className='text-center'>{ t('anket_skoru') + " 2" }</th>
                                  <th className='text-center'>{ t('baslangic_tarihi') }</th>
                                  <th className='text-center'>{ t('bitis_tarihi') }</th>
                                  <th className='text-center'>{ t('uygulanma_tarihi') }</th>
                                  <th className='text-center'>{ t('hata_kodu') }</th>
                                  <th className='text-center'>{ t('tedavi_frekansi_hz') }</th>
                                  <th className='text-center'>{ t('tedavi_dbg_us') }</th>
                                  <th className='text-center'>{ t('ayarlanan_tedavi_akimi_ma') }</th>
                                  <th className='text-center'>{ t('olculen_tedavi_akimi_ma') }</th>
                                  <th className='text-center'>{ t('ortalama_ted_gerilimi_peak_v') }</th>
                                  <th className='text-center'>{ t('ortalama_tedavi_empedansi') }</th>
                                  <th className='text-center'>{ t('hareketlilik_katsayisi') }</th>
                                  <th className='text-center'>{ t('tedavi_sonrasi_pil_gerilimi') }</th>
                                  <th className='text-center'>{ t('su_cihaz_id') }</th>
                                  <th className='text-center'>{ t('su_fw_versiyon') }</th>
                                  <th className='text-center'>{ t('su_mobil_app_versiyon') }</th>
                              </tr>
                          </thead>
                          <tbody>
                              {
                                  takvim.map((item,index) => {
                                      return(
                                          <tr>
                                              <td className='text-center'>{ index + 1 }</td>
                                              <td className='text-center'>{ item.planlanan_tarih }</td>
                                              <td className='text-center'>
                                                  {
                                                      (item.aksiyon == "TEDAVI") &&
                                                      <>
                                                          <span className="badge badge-success">{ t(item.aksiyon) }</span>
                                                      </>
                                                  }                 
                                                  {
                                                      (item.aksiyon == "URINER") &&
                                                      <>
                                                          <Link to={ "/uriner/goruntule/" + item.id }>
                                                            <span className="badge badge-danger">{ t(item.aksiyon) }</span>
                                                          </Link>
                                                      </>
                                                  }                 
                                                  {
                                                      (item.aksiyon == "ANKET") &&
                                                      <>
                                                        <Link to={ "/anket/goruntule/" + item.id }>
                                                          <span className="badge badge-info">{ t(item.aksiyon) }</span>
                                                        </Link>
                                                      </>
                                                  }

                                                  {
                                                    (item.manuel_eklendi) ?
                                                      <span onClick={() => btnAksiyonSil(item.id)} style={{ cursor:'pointer' }} className="badge badge-light text-danger border ml-2">
                                                        <i className="fas fa-trash-alt"></i> { t('sil') }
                                                      </span>
                                                    :
                                                    <></>
                                                  }
                                              </td>
                                              <td className='text-center'>
                                                {
                                                  item.tamamlandi ?
                                                  <>
                                                  <i className="fas fa-check text-success"></i>
                                                  </>
                                                  :
                                                  <>
                                                  <i className="fas fa-times text-danger"></i>
                                                  </>
                                                }
                                              </td>
                                              <td className='text-center'>
                                                {
                                                  item.aksiyon == "ANKET" ? t(item.tedavi_basarisi) : ''
                                                }
                                              </td>
                                              <td className='text-center'>{ item.str_tedavi_surdurme_basarisi }</td>
                                              <td className='text-center'>{ (item.data != null && item.data.length >= 1) ? item.data[0].skor.toFixed(2) : "" }</td>
                                              <td className='text-center'>{ (item.data != null && item.data.length >= 2) ? item.data[1].skor.toFixed(2) : "" }</td>
                                              <td className='text-center'>{ item.data != null ? item.data.baslangic_tarihi : "" }</td>
                                              <td className='text-center'>{ item.data != null ? item.data.bitis_tarihi : "" }</td>
                                              <td className='text-center'>{ item.aksiyon == "TEDAVI" ? "" : item.uygulanma_tarihi }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.hata_kodu : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.frekans : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.darbe_genisligi : "" }</td>
                                              
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.ayarlanan_akim : "" }</td>
                                              
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.akim : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.ortalama_gerilim : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.e_direnc_ort : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.hareketlilik_katsayisi : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.ts_pil_gerilimi : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.cihaz_seri_no : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.firmware_version : "" }</td>
                                              <td className='text-center'>{ (item.aksiyon == "TEDAVI" && item.data != null) ? item.data.mobil_version : "" }</td>
                                          </tr>
                                      )
                                  })
                              }
                          </tbody>
                      </table>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </>
      }

    </Layout>
)
}

const customStyles = {
    content: {
      minWidth : '35%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
}