import React,{ useState } from 'react'
import { MainContext,useContext } from '../../Main/MainContext'
import Layout from '../../Components/Layout'
import { useTranslation } from 'react-i18next'

import { api, server, prefix, endpoints } from '../../Main/Api'
import { useEffect } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate, useParams } from 'react-router-dom'
const MySwal = withReactContent(Swal)

export default function Duzenle() {

  const { user,token }  = useContext(MainContext)
  const { t }           = useTranslation()
  const navigate        = useNavigate()
  const { id }          = useParams();

  const [receteAdi,setReceteAdi]                            = useState();
  const [ayak,setAyak]                                      = useState();
  const [genel,setGenel]                                    = useState();

  const [haftaSayisi,setHaftaSayisi]                        = useState(0);
  const [haftaSayisiEnAz,setHaftaSayisiEnAz]                = useState(0);
  const [haftaSayisiEnFazla,setHaftaSayisiEnFazla]          = useState(0);

  const [haftadakiSiklik,setHaftadakiSiklik]                = useState(0);
  const [haftadakiSiklikEnAz,setHaftadakiSiklikEnAz]        = useState(0);
  const [haftadakiSiklikEnFazla,setHaftadakiSiklikEnFazla]  = useState(0);

  const [tedaviSuresi,setTedaviSuresi]                      = useState(0);
  const [tedaviSuresiEnAz,setTedaviSuresiEnAz]              = useState(0);
  const [tedaviSuresiEnFazla,setTedaviSuresiEnFazla]        = useState(0);

  const [frekans,setFrekans]                                = useState(0);
  const [frekansEnAz,setFrekansEnAz]                        = useState(0);
  const [frekansEnFazla,setFrekansEnFazla]                  = useState(0);

  const [darbeGenisligi,setDarbeGenisligi]                  = useState(0);
  const [darbeGenisligiEnAz,setDarbeGenisligiEnAz]          = useState(0);
  const [darbeGenisligiEnFazla,setDarbeGenisligiEnFazla]    = useState(0);

  const [contentLoading,setContentLoading]                  = useState(false);
  const [loading,setLoading]                                = useState(false);

  useEffect(() => {
    setContentLoading(true)
    let url = server + prefix + endpoints.recete_bilgi.replace(":id",id);
    
    api.request(url,"GET",null,{
      "Authorization" : "Bearer " + token
    }).then((response) => {
      setContentLoading(false)
      const { hata,data } = response.data;

      if(!hata){
        setHaftaSayisi(data.ayarlar.en_az_hafta_sayisi)
        setHaftaSayisiEnAz(data.ayarlar.en_az_hafta_sayisi)
        setHaftaSayisiEnFazla(data.ayarlar.en_fazla_hafta_sayisi)

        setHaftadakiSiklik(data.ayarlar.en_az_haftadaki_siklik)
        setHaftadakiSiklikEnAz(data.ayarlar.en_az_haftadaki_siklik)
        setHaftadakiSiklikEnFazla(data.ayarlar.en_fazla_haftadaki_siklik)

        setTedaviSuresi(data.ayarlar.en_az_tedavi_suresi)
        setTedaviSuresiEnAz(data.ayarlar.en_az_tedavi_suresi)
        setTedaviSuresiEnFazla(data.ayarlar.en_fazla_tedavi_suresi)

        setFrekans(data.ayarlar.en_az_frekans)
        setFrekansEnAz(data.ayarlar.en_az_frekans)
        setFrekansEnFazla(data.ayarlar.en_fazla_frekans)

        setDarbeGenisligi(data.ayarlar.en_az_darbe_genisligi)
        setDarbeGenisligiEnAz(data.ayarlar.en_az_darbe_genisligi)
        setDarbeGenisligiEnFazla(data.ayarlar.en_fazla_darbe_genisligi)

        setReceteAdi(data.recete.recete_adi)
        setAyak(data.recete.uygulanacak_ayak)
        setGenel(data.recete.genel ? "1" : "0")
        setHaftaSayisi(data.recete.hafta_sayisi)
        setHaftadakiSiklik(data.recete.siklik)
        setTedaviSuresi(data.recete.tedavi_suresi)
        setFrekans(data.recete.frekans)
        setDarbeGenisligi(data.recete.darbe_genisligi)
      }else{
        navigate("/tedavi-protokolu/listele")
      }
    }).catch((err) => {
      setContentLoading(false)
    })
  },[ ])

  const guncelle = () => {

    setLoading(true);
    const data = {
      genel,
      recete_adi : receteAdi,
      hafta_sayisi : haftaSayisi,
      siklik : haftadakiSiklik,
      tedavi_suresi : tedaviSuresi,
      frekans,
      darbe_genisligi : darbeGenisligi,
      uygulanacak_ayak : ayak
    }

    let url = server + prefix + endpoints.recete_guncelle.replace(":id",id);
    api.request(url,"PUT",data,{
      "Authorization" : "Bearer " + token
    }).then((response) => {

      setLoading(false);

      const { hata, mesaj } = response.data;
      
      MySwal.fire({
        allowOutsideClick : false,
        allowEscapeKey : false,
        titleText : mesaj,
        confirmButtonText : t('tamam')
      }).then((x) => {
      if(x.isConfirmed){
        if(!hata){
          navigate("/tedavi-protokolu/goruntule/" + id)
        }
      }
    })

    });
  }

  return (
    <Layout baslik={ t('tp_duzenle') }>
    
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">

            {
              contentLoading ?
              <>
               <div className="text-center mt-5">
                    <i className="fas fa-spinner fa-spin fa-2x mb-2"></i>
                    <p>{ t("yukleniyor...") }</p>
                </div>
              </>
              :
              <>
              <div className="card-body">

                <div className="row">
                  <div className="col-md-6">

                    <div className="form-group">
                      <label htmlFor="recete_adi">{ t('tp_adi') }</label>
                      <input id="recete_adi" value={ receteAdi } onChange={(e) => setReceteAdi(e.target.value)} type="text" className="form-control" />
                    </div>

                    {
                      user.rol == "ADMIN" &&
                      <>
                      <div className="form-group">
                        <label htmlFor="genel">{ t('genel_recete') }</label>
                        <select defaultValue={ genel } value={ genel } onChange={(e) => setGenel(e.target.value)} id="genel" className="form-control">
                          <option value="">{ t('secim_yapilmadi') }</option>
                          <option value="1">{ t('EVET') }</option>
                          <option value="0">{ t('HAYIR') }</option>
                        </select>
                      </div>
                      </>
                    }

                    <div className="form-group">
                      <label htmlFor="ayak">{ t('ayak') }</label>
                      <select defaultValue="" value={ ayak } onChange={(e) => setAyak(e.target.value)} id="ayak" className="form-control">
                        <option value="">{ t('secim_yapilmadi') }</option>
                        <option value="SOL">{ t('SOL') }</option>
                        <option value="SAG">{ t('SAG') }</option>
                        <option value="CIFT">{ t('CIFT') }</option>
                      </select>
                    </div>

                  </div>

                  <div className="col-md-6">

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="hafta_sayisi" style={{ flex : 1 }}>{ t('hafta_sayisi') }</label>
                        <label htmlFor="hafta_sayisi">{ haftaSayisi }</label>
                      </div>
                      <input id="hafta_sayisi" type="range" className="custom-range" value={ haftaSayisi } min={ haftaSayisiEnAz } max={ haftaSayisiEnFazla } step="1" onChange={(e) => setHaftaSayisi(e.target.value)}/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="haftadaki_siklik" style={{ flex : 1 }}>{ t('haftadaki_siklik') }</label>
                        <label htmlFor="haftadaki_siklik">{ haftadakiSiklik }</label>
                      </div>
                      <input id="haftadaki_siklik" type="range" className="custom-range" value={ haftadakiSiklik } min={ haftadakiSiklikEnAz } max={ haftadakiSiklikEnFazla } step="1" onChange={(e) => setHaftadakiSiklik(e.target.value)}/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="tedavi_suresi" style={{ flex : 1 }}>{ t('tedavi_suresi_dk') }</label>
                        <label htmlFor="tedavi_suresi">{ tedaviSuresi }</label>
                      </div>
                      <input id="tedavi_suresi" type="range" className="custom-range" value={ tedaviSuresi } min={ tedaviSuresiEnAz } max={ tedaviSuresiEnFazla } step="1" onChange={(e) => setTedaviSuresi(e.target.value)}/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="frekans" style={{ flex : 1 }}>{ t('frekans_hz') }</label>
                        <label htmlFor="frekans">{ frekans }</label>
                      </div>
                      <input id="frekans" type="range" className="custom-range" value={ frekans } min={ frekansEnAz } max={ frekansEnFazla } step="1" onChange={(e) => setFrekans(e.target.value)}/>
                    </div>

                    <div className="form-group">
                      <div style={ { display:'flex' } }>
                        <label htmlFor="darbe_genisligi" style={{ flex : 1 }}>{ t('dbg_mikro_sn') }</label>
                        <label htmlFor="darbe_genisligi">{ darbeGenisligi }</label>
                      </div>
                      <input id="darbe_genisligi" type="range" className="custom-range" value={ darbeGenisligi } min={ darbeGenisligiEnAz } max={ darbeGenisligiEnFazla } step={ 20 } onChange={(e) => setDarbeGenisligi(e.target.value)}/>
                    </div>

                  </div>

                  <div className="col-md-12">
                    <button onClick={() => guncelle()} className="btn btn-dark btn-block">
                      {
                        loading ? 
                        <>
                          <i className="fas fa-spinner fa-spin"></i> { t("isleniyor") }
                        </>
                        :
                        <>
                          <i className="fas fa-check"></i> { t("guncelle") }
                        </>
                      }
                    </button>
                  </div>
                </div>                

              </div>
              </>
            }

          </div>
        </div>
      </div>

    </Layout>
  )
}
