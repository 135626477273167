import React from 'react'
import { MainContext,useContext } from '../Main/MainContext'
import Layout from '../Components/Layout'
import { useTranslation } from 'react-i18next'

export default function Dashboard() {

  const { user,token }  = useContext(MainContext)
  const { t }           = useTranslation()

  return (
    <Layout baslik={ t('Dashboard') }>
    
    </Layout>
  )
}
