import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { MainContext,useContext } from '../Main/MainContext';
import { Link } from 'react-router-dom';

import Logo from '../assets/logo.png'
import { useTranslation } from 'react-i18next';

export default function LeftSidebar() {

  const { t } = useTranslation();

  const { sidebarState,setSidebarState,setLogin,setToken,setUser,user } = useContext(MainContext)

  const logout = () => {
    setUser(null);
    setToken("")
    setLogin(false);
  }

  return (
    <Sidebar breakPoint='sm' toggled={ sidebarState } backgroundColor="#8DDFCB" onBackdropClick={ () => setSidebarState(false) }>
        
        <div className="sideBarHeader" style={ { display:'flex',flexDirection:'column',alignItems:'center', backgroundColor : '#FFF',padding : 12 } }>
          <Link to="/dashboard">
            <img className='img img-fluid' style={ { width : 64, height : 64 } } src={ Logo }/>
          </Link>
          <strong>SmartUric</strong>
          <span>{ user.ad + " " + user.soyad }</span>
        </div>

        <Menu>
          <MenuItem icon={ <i className="fas fa-star"/> } component={<Link to="/dashboard" />}>Dashboard</MenuItem>
          {
            user.rol == "ADMIN" &&
            <>
            <MenuItem icon={ <i className="fas fa-users"/> } component={<Link to="/kullanici/listele" />}>{ t('kullanicilar') }</MenuItem>
            <MenuItem icon={ <i className="fas fa-heartbeat"/> } component={<Link to="/hasta/listele" />}>{ t('hastalar') }</MenuItem>
          
            <SubMenu icon={ <i className="fas fa-receipt"/> } label={ t('tedavi_protokolu') }>
              <MenuItem component={<Link to="/tedavi-protokolu/listele" />}>{ t('listele') }</MenuItem>
              <MenuItem component={<Link to="/tedavi-protokolu/ayarlari" />}>{ t('ayarlar') }</MenuItem>
            </SubMenu>
          
            <SubMenu className='d-none' icon={ <i className="fas fa-lock"/> } label={ t('yetkiler') }>
              <MenuItem>{ t('adminler') }</MenuItem>
              <MenuItem>{ t('sub_adminler') }</MenuItem>
              <MenuItem>{ t('spler') }</MenuItem>
            </SubMenu>
            </>
          }
            
          {
            user.rol == "SUBADMIN" &&
            <>
            <MenuItem icon={ <i className="fas fa-users"/> } component={<Link to="/kullanici/listele" />}>{ t('kullanicilar') }</MenuItem>
            <MenuItem icon={ <i className="fas fa-heartbeat"/> } component={<Link to="/hasta/listele" />}>{ t('hastalar') }</MenuItem>
          
            <SubMenu icon={ <i className="fas fa-receipt"/> } label={ t('tedavi_protokolu') }>
              <MenuItem component={<Link to="/tedavi-protokolu/listele" />}>{ t('listele') }</MenuItem>
            </SubMenu>
            </>
          }

          {
            user.rol == "SP" &&
            <>
            <MenuItem icon={ <i className="fas fa-heartbeat"/> } component={<Link to="/hasta/listele" />}>{ t('hastalar') }</MenuItem>
          
            <SubMenu icon={ <i className="fas fa-receipt"/> } label={ t('tedavi_protokolu') }>
              <MenuItem component={<Link to="/tedavi-protokolu/listele" />}>{ t('listele') }</MenuItem>
            </SubMenu>
            </>
          }

          <MenuItem icon={ <i className="fas fa-sign-out-alt"/> } onClick={() => logout()}>{ t('cikis_yap') }</MenuItem>
        </Menu>
    </Sidebar>
  )
}